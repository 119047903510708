import React, { FC, useState } from 'react'
import { Modal, Fade, Container, Card, CardContent, Button, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { useGlobalStyles } from '@styles'
import { useAuth } from '@hooks'

import { useStyles } from './styles'
import { QUERY_KEYS } from '@api'

interface Props {
  isOpen: boolean
  handleClose: () => void
}

export const LogOutModal: FC<Props> = ({ isOpen, handleClose }) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  const [isThisOpen, setThisIsOpen] = useState(true)
  const { logout } = useAuth()

  const handleLogout = () => {
    const idToken = window.localStorage.getItem(QUERY_KEYS.ID_TOKEN) || ''
    logout(idToken)
    setThisIsOpen(false)
  }

  return (
    <>
      <Modal
        open={isOpen && isThisOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={globalClasses.modal}
      >
        <Fade in={isOpen} timeout={500}>
          <Container className={globalClasses.modalContainer}>
            <Card className={`${globalClasses.modalCard} ${classes.modalCard}`}>
              <CloseIcon className={globalClasses.modalCloseIcon} onClick={handleClose} />

              <CardContent>
                <div className={globalClasses.formGroup}>
                  <Typography className={classes.title}>
                    ¿Está seguro de que desea salir?
                  </Typography>
                </div>
                <div className={classes.actions}>
                  <Button type="button" variant="outlined" onClick={handleClose}>
                    Cancelar
                  </Button>            
                  <Button
                    onClick={() => handleLogout()}
                    style={{ marginLeft: 10 }}
                    type="button"
                    variant="contained"
                  >
                    Aceptar{' '}
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Container>
        </Fade>
      </Modal>
    </>
  )
}
