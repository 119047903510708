import React, { FC, useContext } from 'react'
import { Container, Hidden, Button } from '@material-ui/core'
import { NavLink, useLocation } from 'react-router-dom'

import { AuthContext, LoginContext } from '@contexts'
import logoFooter from '@assets/images/takeda-oncology-vertical.png'
import formMedica from '@assets/images/formMedica.png'
import sobre from '@assets/images/sobre.png'

import { useStyles } from './styles'

const Footer = () => {
  const classes = useStyles()
  const { isAuthenticated } = useContext(AuthContext)
  const { setIsOpen } = useContext(LoginContext)
  const location = useLocation()

  const RenderLogo: FC<{ margin: number }> = ({ margin }) => {
    return (
      <div style={{ margin }}>
        <img src={logoFooter} alt="Takeda Oncology logo" className={classes.logo} />
      </div>
    )
  }

  const RenderFormMedica: FC<{ margin: number; srcImagen: string; nameClass: number }> = ({
    margin,
    srcImagen,
    nameClass,
  }) => {
    return isAuthenticated ? (
      <div
        style={{ margin }}
        className={nameClass === 0 ? classes.formMedicaMobile : classes.formMedica}
      >
        <NavLink to="/form-medica">
          <img src={srcImagen} alt="Formulario medica logo" />
        </NavLink>
      </div>
    ) : (
      <div
        style={{ margin }}
        className={nameClass === 0 ? classes.formMedicaMobile : classes.formMedica}
      >
        <Button
          onClick={() => setIsOpen(true)}
          style={{ textDecoration: 'none' }}
          className={nameClass === 0 ? classes.formMedicaMobile : classes.formMedica}
        >
          <img src={srcImagen} alt="Formulario medica logo" />
        </Button>
      </div>
    )
  }

  return (
    <footer className={classes.footer}>
      <Container className={classes.container}>
        {location.pathname === '/' && (
          <div>
            <Hidden smDown>
              <RenderFormMedica margin={10} srcImagen={formMedica} nameClass={1} />
            </Hidden>

            <Hidden mdUp>
              <RenderFormMedica margin={10} srcImagen={sobre} nameClass={0} />
            </Hidden>
          </div>
        )}
        <div className={classes.left}>
          <div className={classes.leftTop}>
            <nav className={classes.nav}>
              <ul>
                <li>
                  <NavLink to="/terms-and-conditions">Términos y condiciones</NavLink>
                </li>

                <li>
                  <NavLink to="/privacy-policies">Políticas de Privacidad</NavLink>
                </li>
              </ul>
            </nav>
            <Hidden mdUp>
              <RenderLogo margin={10} />
            </Hidden>
            <p>
              Takeda Argentina S.A. Avenida del Libertador 7208,
              <Hidden lgUp>
                <br />
              </Hidden>
              piso 14, CABA-Argentina.{' '}
              <Hidden lgUp>
                <br />
              </Hidden>
              Línea de atención al paciente: 0800-266-5287
            </p>
          </div>

          <p style={{ marginBottom: 0, marginTop: 0, lineHeight: 1.4 }}>
            Material exclusivo para el profesional de la salud – Prohibida su distribución al
            público en general. Código del Material:{' '}
            <Hidden smUp>
              <br />
            </Hidden>
            C-APROM/AR/INI/0070
            <Hidden smUp>
              <br />
            </Hidden>{' '}
            <Hidden mdDown> - </Hidden>
            Fecha de elaboración:{' '}
            <Hidden smUp>
              <br />
            </Hidden>
            Junio 2021
          </p>
        </div>
        <Hidden smDown>
          <RenderLogo margin={10} />
        </Hidden>
      </Container>
    </footer>
  )
}

export { Footer }
