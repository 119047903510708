import { useMutation } from 'react-query'
import { AuthApi } from './../useAuth/apiTakeda'
import { UserCache } from '@types'
import { QUERY_KEYS } from '@api'
import { removeAuthHeader } from '@utils'
import { useEffect } from 'react'

const useLogoutByToken = (idToken:string) => {
  const [logoutApi] = useMutation(AuthApi.logout, {
    onSuccess: async (user: UserCache) => {
      localStorage.removeItem(QUERY_KEYS.ID_TOKEN)
      localStorage.removeItem(QUERY_KEYS.SESSION_TOKEN)
      setTimeout(() => {
        window.location.href = '/'
      }, 1000)
    },
  })

  useEffect(() => {
    logoutApi({idToken})
    localStorage.removeItem(QUERY_KEYS.ID_TOKEN)
    localStorage.removeItem(QUERY_KEYS.SESSION_TOKEN)
    setTimeout(() => {
      window.location.href = '/'
    }, 1000)
  }, [logoutApi])

  return null
}

export { useLogoutByToken }