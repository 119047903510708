import { makeStyles } from '@material-ui/core'

import { COLORS } from '@styles'

const useStyles = makeStyles(theme => ({
  speaker: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1',
    padding: '30px',
    borderRadius: '35px',
    cursor: 'pointer',
    boxShadow: '5px 5px 50px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: COLORS.WHITE,
  },
  body: {
    marginLeft: '15px',
  },
  name: {
    fontSize: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.2,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: COLORS.GRAY_HEADER,
  },
  profileImage: {
    objectFit: 'cover',
    width: 87,
    height: 87,
  },
  subTitle: {
    fontSize: '14px',
    marginTop: 5,
    color: COLORS.PINK_SWAN,
  },
}))

export { useStyles }
