import React, { FC } from 'react'
import { Helmet } from 'react-helmet'

import { Footer, NavBar } from './components'

interface Props {
  hideHeader?: boolean
  headTitle?: string
  headDescription?: string
}

export const Layout: FC<Props> = ({ children, hideHeader = false, headTitle, headDescription }) => {
  return (
    <>
      <Helmet>
        <title>{headTitle}</title>
        <meta id="meta-description" name="description" content={headDescription} />
        <meta id="meta-title" name="title" content={headTitle} />
        <meta id="og-title" property="og:title" content={headTitle} />
      </Helmet>
      {!hideHeader && <NavBar />}
      {children}
      <Footer />
    </>
  )
}
