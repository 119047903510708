import React from 'react'
import { Container, Grid } from '@material-ui/core'

import { Header, Layout, Main } from '@components'
import background from '@assets/images/bg-privacy-policies.jpg'

import { useStyles } from '../styles'

export const TermsAndConitions = () => {
  const classes = useStyles()

  return (
    <Layout>
      <Main showGoBack className={classes.main}>
        <img src={background} className={classes.bgHeader} alt="Imagen de cabecera" />
        <Container className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <Header title="TÉRMINOS Y CONDICIONES DE USO" titleClass={classes.title} />
            </Grid>
            <Grid item xs={12} md={7}>
              <p>
                A continuación, se encuentran los Términos y Condiciones de uso (“Términos y
                Condiciones”) de ESPACIOONCOLOGIA.COM (“Sitio”).
              </p>

              <p>
                El responsable del Sitio es TAKEDA ARGENTINA S.A. (“Takeda”), con domicilio en Av.
                del Libertador 7208, piso 14, Ciudad Autónoma de Buenos Aires, República Argentina,
                N.º de CUIT 30-70828215-0, inscrita en fecha 12 de marzo de 2003 ante la Inspección
                General de Justicia bajo el número 3352 del libro 20, Tomo “A” de Sociedades por
                Acciones.
              </p>

              <p>
                Takeda es parte de un grupo de empresas con operaciones a nivel global. La
                organización de Takeda incluye a la compañía matriz, Takeda Pharmaceutical Company
                Limited, y sus entidades afiliadas. Para obtener la información de contacto de su
                afiliada local, por favor, consulte el sitio web de Takeda:{' '}
                <a href="www.takeda.com/es-ar" target="_blank">
                  www.takeda.com/es-ar
                </a>
              </p>

              <p>
                Toda persona que acceda utilice y navegue por el Sitio (“Visitante”) y también toda
                persona suscripta al presente sitio (“Usuario”), se encuentra obligada a cumplir y
                respetar los presentes Términos y Condiciones.
              </p>

              <p>
                A través del acceso, utilización, navegación y suscripción en el Sitio se está
                contratando la utilización de los servicios prestados por Takeda. Ello, de acuerdo a
                las cláusulas dispuestas en los Términos y Condiciones, que constituye un contrato
                para los Visitantes, Usuarios y Takeda. Si no está de acuerdo totalmente con los
                Términos y Condiciones, le solicitamos que no acceda ni utilice el Sitio.
                <br />
                Este Sitio está dirigido a Profesionales de la Salud debidamente matriculados. En el
                caso que Usted no sea un Profesional de la Salud, no podrá continuar navegando en el
                sitio ni inscribirse a las actividades o eventos que el Sitio ofrezca.
              </p>

              <ul style={{ listStyleType: 'upper-roman' }}>
                <li>
                  <h3>Privacidad</h3>

                  <p>
                    Lo invitamos gentilmente a leer las Políticas de Privacidad que también rigen
                    para ESPACIO ONCOLOGIA&#174; a los fines de comprender nuestras prácticas y
                    políticas con respecto a la privacidad del usuario y/o visitante.
                  </p>
                </li>
                <li>
                  <h3>Contenidos y Usos Permitidos del Sitio</h3>

                  <ul style={{ listStyleType: 'upper-alpha' }}>
                    <li>
                      <h4>Contenidos</h4>
                      <p>
                        Toda la información incorporada en nuestro Sitio, incluidos sus textos,
                        imágenes, audio, video y opiniones es suministrada solamente a título
                        informativo, no puede ni debe ser utilizada para diagnóstico o tratamiento y
                        bajo ninguna circunstancia sustituye, complementa ni reemplaza el consejo
                        médico profesional. La utilización de la información corre bajo su exclusivo
                        riesgo y responsabilidad.
                      </p>

                      <p>
                        El fin de la plataforma es sólo para fines educativos e informativos y no
                        está destinado a sustituir el asesoramiento médico informado o las
                        recomendaciones de los profesionales de la salud. No debe ser invocado como
                        base para tomar cualquier decisión o tomar cualquier acción.{' '}
                      </p>

                      <p>
                        Este sitio web puede contener información sobre productos que pueden no
                        estar disponibles en todos los países, o puede estar disponible bajo
                        diferentes marcas comerciales, para diferentes indicaciones, en diferentes
                        dosis, o en diferentes concentraciones. Nada contenido aquí debe ser
                        considerado una solicitud, promoción o indicación para cualquier medicamento
                        recetado, incluyendo los que se encuentran en desarrollo. Toda la
                        información y referencias relacionadas con el manejo, almacenamiento o uso
                        de los productos, que estén implícitas en el recurso pertinente, se basan en
                        los conocimientos de Takeda en el momento en que se hacen dichas
                        recomendaciones.
                      </p>

                      <p>
                        Realizamos todos los esfuerzos razonables para que la información sea
                        precisa y se mantenga actualizada, no obstante ello, no garantizamos que la
                        misma sea completa y exacta, o que carezca de imprecisiones técnicas.
                        <br />
                        La información brindada en el Sitio está sujeta a cambios sin previo aviso.
                        Los detalles y las imágenes de los productos se exhiben con fines
                        publicitarios y son meramente ilustrativas.{' '}
                      </p>

                      <p>
                        Todas las marcas comerciales, logotipos, ilustraciones y fotografías que
                        aparezcan en los sitios están protegidas por las leyes de propiedad
                        intelectual. Se prohíbe su reproducción, distribución, alteración y todo
                        otro uso alternativo de los mismos.
                        <br />
                        Se podrá establecer un enlace a los sitios de Takeda solamente si se cuenta
                        con la autorización previa de la citada compañía. Takeda no se
                        responsabiliza por aquellos contenidos publicados por un tercero, que
                        remitan a los sitios de Takeda por medio de un enlace.
                        <br />
                        Para la comodidad de los Visitantes, Takeda podrá establecer enlaces a otros
                        sitios web. Ello no implica aprobación alguna, por parte de Takeda, de los
                        contenidos de tales sitios, ni asunción alguna de responsabilidad, también
                        por parte de Takeda, incluida la responsabilidad por daños y perjuicios que
                        pudiere derivarse de tales contenidos.
                      </p>
                    </li>

                    <li>
                      <h4>Usos Permitidos</h4>

                      <p>
                        Sólo se permite el uso del Sitio si se aceptan y respetan los presentes
                        Términos y Condiciones. Se consideran usos prohibidos y los Usuarios se
                        obligan a no utilizar el Sitio para:
                      </p>

                      <ol>
                        <li>
                          Enviar spam, publicidad no solicitada o no autorizada, cartas encadenadas,
                          o cualquier otro tipo de comunicaciones ilícitas;
                        </li>
                        <li>
                          Vulnerar derechos marcarios o cualquier otra propiedad intelectual de
                          Takeda o de terceros;{' '}
                        </li>
                        <li>
                          Suscribir más de una cuenta de Usuario a nombre de una misma persona;
                        </li>
                        <li>
                          Realizar manifestaciones falsas o proporcionar información falsa sobre el
                          titular del nombre de usuario y contraseña;
                        </li>
                        <li>
                          Cargar, transmitir, o facilitar material que contenga virus informáticos o
                          cualquier otro código, archivo o programa informático diseñado para
                          interrumpir, destruir o limitar la funcionalidad de cualquier software o
                          hardware o equipo de telecomunicaciones de Takeda;
                        </li>
                        <li>
                          Utilizar información o contenidos publicados en el Sitio para destinarlos
                          a un servicio que compita con Takeda;
                        </li>
                        <li>
                          Usar el Sitio en forma contraria a los Términos y Condiciones, Política de
                          Privacidad, la ley y las buenas costumbres, o de cualquier forma que pueda
                          dañar, desprestigiar, sobrecargar o perjudicar a Takeda o a terceros;
                          <br />
                          El presente listado de conductas prohibidas es una enunciación meramente
                          ejemplificativa de las infracciones que pueden cometer los Visitantes y
                          Usuarios.
                        </li>
                      </ol>
                    </li>
                  </ul>
                </li>
                <li>
                  <h4>Propiedad Intelectual</h4>

                  <p>
                    Takeda es titular de la propiedad intelectual y derechos de autor sobre los
                    diseños, textos, marcas, gráficos, imágenes, videos, software, música, sonidos y
                    otros contenidos existentes en el Sitio (“Contenidos de Takeda”).
                    <br /> Los Contenidos de Takeda no podrán ser objeto de modificaciones, copias,
                    duplicados o imitaciones y tampoco podrán ser reproducidos, descargados,
                    publicados, transmitidos o vendidos total o parcialmente, sin el previo
                    consentimiento por escrito de Takeda.
                    <br /> Los Usuarios y Visitantes no podrán cargar o republicar Contenidos de
                    Takeda en ningún otro sitio de Internet o cualquier otro medio de publicación,
                    ni integrar la información en ninguna otra base de datos o compilación.
                    <br /> Sólo se les concede el derecho a los Usuarios y Visitantes a realizar
                    copias, descargas e impresiones de Contenidos de Takeda, siempre que sean para
                    uso personal y se mantengan intactos todos los derechos de autor y propiedad
                    intelectual de Takeda, así como que se haga referencia a la fuente Esta licencia
                    podrá ser revocada en cualquier momento y sin preaviso, con o sin causa. Salvo
                    esta última excepción, nada de lo dispuesto en los presentes Términos y
                    Condiciones deberá interpretarse en el sentido de otorgar una licencia sobre
                    derechos de propiedad intelectual, implícitamente o de cualquier otra forma.
                  </p>

                  <p>
                    El uso del Sitio o del Contenido de Takeda en contravención a los Términos y
                    Condiciones o la ley, le otorga a Takeda el derecho a cancelar la suscripción de
                    el/los Usuarios en infracción y a reclamar los daños y perjuicios que
                    correspondiesen.
                  </p>
                </li>
                <li>
                  <h4>Cancelación</h4>

                  <p>
                    Takeda tiene la facultad de dar de baja, con o sin expresión de causa, cualquier
                    Contenido de Takeda o suscripción de Usuarios, en cualquier momento sin previo
                    aviso. En especial, podrá Takeda cancelar la suscripción o dar de baja
                    Contenidos de Usuarios cuando se hallasen en violación a los presentes Términos
                    y Condiciones.
                  </p>
                </li>
                <li>
                  <h4>Limitación de Responsabilidad</h4>
                  <strong>Takeda no es responsable por:</strong>
                  <ol>
                    <li>
                      Fallas técnicas. Takeda no es responsable por errores, omisiones,
                      interrupciones, eliminaciones, defectos, retrasos de funcionamiento o de
                      transmisión, fallos de la línea de comunicaciones, robos, destrucción, acceso
                      no autorizado o alteración del acceso al Sitio del Usuario.
                    </li>
                    <li>
                      Ataques de terceros: Queda a su exclusivo riesgo de los Visitantes y/o
                      Usuarios el uso del Sitio. Ni Takeda ni ninguna persona o empresa vinculado a
                      la creación, producción o desarrollo del sitio será responsable por ninguna
                      clase de daños que puedan resultar de ese uso. Takeda tampoco será responsable
                      de cualquier daño a su equipo de computación u otro, o por cualquier virus que
                      pueda infectarlo como consecuencia del uso de la página o de la transferencia
                      de datos total o parcial a su equipo.
                    </li>
                  </ol>
                </li>
                <li>
                  <h4>Denuncias</h4>
                  <p>
                    En caso de que cualquier Visitante o Usuario sostenga que en el Sitio podría
                    haber una violación a los Términos y Condiciones o tuviese cualquier otro
                    reclamo, podrá dirigir tal denuncia a la siguiente dirección de correo
                    electrónico:{' '}
                    <a href="legalargentina@takeda.com" target="_blank">
                      legalargentina@takeda.com
                    </a>
                  </p>
                </li>
                <li>
                  <h4>Miscelánea</h4>

                  <ul style={{ listStyleType: 'upper-alpha' }}>
                    <li>
                      Ley Aplicable Los presentes Términos y Condiciones deben ser interpretados
                      conforme a las leyes de la República Argentina, excluyendo la aplicación de
                      cualquier norma de conflicto que reenvíe a la aplicación de otra ley.
                    </li>
                    <li>
                      Jurisdicción Todas las controversias que deriven de estos Términos y
                      Condiciones o que guarden relación con éstos serán resueltos por los
                      tribunales ordinarios de la Ciudad Autónoma de Buenos Aires, con renuncia a
                      cualquier otro fuero o jurisdicción.
                    </li>
                    <li>
                      Notificaciones Para todos los efectos judiciales y extrajudiciales, Takeda
                      constituye domicilio legal Av. del Libertador 7208, piso 14, Ciudad Autónoma
                      de Buenos Aires, donde serán válidas todas las notificaciones referidas al uso
                      del Sitio. Las notificaciones deberán ser realizadas por carta documento,
                      telegrama, o cualquier otro medio fehaciente y no se considerarán cursadas si
                      no existiese constancia de recepción por una persona autorizada por Takeda a
                      tales fines (funcionarios, empleados, apoderados, etc.)
                    </li>
                    <li>
                      Modificación Takeda se reserva el derecho a modificar total o parcialmente los
                      Términos y Condiciones, en cualquier momento y sin previo aviso. Si sucede
                      cualquier modificación, se publicará la misma en el Sitio y si el Usuario
                      persiste en la utilización de los servicios prestados, se considerará que ha
                      aceptado implícitamente los nuevos Términos y Condiciones. Los Usuarios tienen
                      la responsabilidad de visitar regularmente el Sitio para controlar si ha
                      habido cambios a los Términos y Condiciones de uso de Takeda.
                    </li>
                  </ul>
                </li>
              </ul>

              <p style={{ marginTop: 40 }}>Código del Material: C-APROM/AR/INI/0070</p>
              <p>Fecha de elaboración: Junio 2021</p>
            </Grid>
          </Grid>
        </Container>
      </Main>
    </Layout>
  )
}
