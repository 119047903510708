import * as yup from 'yup'
import { ERRORS } from '@constants'

export const validationSchema = yup.object().shape({
  title: yup.string().required(ERRORS.REQUIRED.DEFAULT),
  name: yup.string().required(ERRORS.REQUIRED.DEFAULT),
  lastName: yup.string().required(ERRORS.REQUIRED.DEFAULT),
  email: yup.string().required(ERRORS.REQUIRED.DEFAULT).email(ERRORS.FORMAT.EMAIL),
  country: yup.string().required(ERRORS.REQUIRED.DEFAULT),
  password: yup
    .string()
    .trim()
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z#?!@$%^&*\d]{8,}$/, ERRORS.FORMAT.PASSWORD)
    .required(ERRORS.REQUIRED.DEFAULT),
  password2: yup.string().oneOf([yup.ref('password')], ERRORS.FORMAT.PASSWORD_MATCH),
  specialty: yup.string().required(ERRORS.REQUIRED.DEFAULT),
  termsAndConditions: yup.boolean().oneOf([true], ERRORS.REQUIRED.DEFAULT),
})
