export interface HomeCarouselItem {
  id: number
  title: string
  link: string
  description: string
  imageUrl: string
}

export type Tag = {
  id: number
  name: string
  isActive: boolean
}

export interface Speaker {
  country: string
  email: string
  id: number
  lastName: string
  name: string
  profilePictureUrl: string
  biography?: string
  specialty: string
  created_at?: string
  updated_at?: string
}
export interface Webinar {
  id: number
  date: Date
  duration: number
  thematic: string
  title: string
  abstract: string
  description: string
  videoId: string
  presentationVideoLink: string
  therapeuticArea: TherapeuticArea
  category: string
  isActive: boolean
  isSalient: boolean
  joinUrl: string
  created_at: string
  updated_at: string
  tags: Tag[]
  speakers: Speaker[]
  imageUrl: string
  status: WebinarStatus
  registrants: Registrant[]
}

export interface Registrant {
  name: string
  lastName: string
  email: string
  hasParticipated?: boolean
  joinUrl: string
  origin?: RegistrantOrigin
}

export type TherapeuticArea = {
  id: number
  name: string
  isActive: boolean
  color: string
}

export type User = {
  id: string
  title: string
  name: string
  lastName: string
  email: string
  specialty: string
  country: string
  isCompleted?: boolean
  takedaId?: string
}

export type UserCache = Omit<User, 'sessionToken' | 'isCompleted'>

export type VerifyTokenResponse = {
  user: {
    stateToken: string
  }
}

export type ApiResponse = {
  message?: string
  type: string
  field: string
}

export enum WebinarStatus {
  pending = 'pending',
  completed = 'completed',
  draft = 'draft',
  discarded = 'discarded',
  finalized = 'finalized',
  started = 'started',
}
export enum WeibarActionsButton {
  showVideo = 'showVideo',
  goToWebinar = 'goToWebinar',
  register = 'register',
  disabled = 'disabled',
}

export enum RegistrantOrigin {
  web = 'web',
  zoom = 'zoom',
}

export enum ErrorHandlers {
  DuplicateEntryError = 'DuplicateEntryError',
  Forbidden = 'Forbidden',
  Unauthorized = 'Unauthorized',
  GenericError = 'GenericError',
  ResourceFieldInUse = 'ResourceFieldInUse',
  CustomMessage = 'CustomMessage',
  ResourceNotFound = 'ResourceNotFound',
  ValidationError = 'ValidationError',
  UserAlreadyRegistered = 'UserAlreadyRegistered',
}

export enum TextWebinarActionButton {
  showVideo = 'Ver video',
  soonVideo = 'Próximamente video',
  access = 'Accedé',
  registered = 'Ya estás registrado en esta actividad',
  watchLive = 'Ver vivo',
}

export type FormMedica = {
  id: number
  mailTo: string
  mailFrom: string
  subject: string
  request: string
  sendMeACopy: boolean
  userId: string
  therapeuticAreasId: string
}
