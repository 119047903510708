import { api } from '@utils'
import { VerifyTokenResponse } from '@types'

import { ResetPasswordPayload } from './types'

const recoveryPassword = async (payload: { email: string }) => {
  try {
    const response = await api.post('/auth/recovery-password', payload)
    return response.data
  } catch (err) {
    throw err.response.data.error
  }
}

const verifyToken = async (payload: { recoveryToken: string | null }) => {
  try {
    const response = await api.post<VerifyTokenResponse>('/auth/verify-token', { user: payload })
    return response.data.user.stateToken
  } catch (err) {
    throw err.response.data.error.type
  }
}

const resetPassword = async (payload: ResetPasswordPayload) => {
  try {
    const response = await api.post('/auth/reset-password', { user: payload })
    return response.data
  } catch (err) {
    throw err.response.data.error
  }
}

export const AccountApi = { verifyToken, resetPassword, recoveryPassword }
