import React, { FC } from 'react'
import {
  Modal,
  Fade,
  Container,
  Card,
  CardContent,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useGlobalStyles } from '@styles'
import { useStyles } from './styles'
import icon from '@assets/images/icon-warning-signal.svg'

interface Props {
  ongoingWebinar?: any
  handleClose: () => void
}

const ModalLastWebinar: FC<Props> = ({ children, ongoingWebinar, handleClose }) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (isMobile && ongoingWebinar !== undefined)
    return (
      <>
        <Modal
          open={true}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={globalClasses.modal}
        >
          <Fade in={true} timeout={500}>
            <Container className={globalClasses.modalContainer}>
              <Card className={globalClasses.modalCard}>
                <CloseIcon className={classes.closebtn} onClick={handleClose} />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <div className={classes.iconRoot}>
                        <img src={icon} className={classes.iconMb} alt="Icono" />
                      </div>
                    </Grid>
                    <Grid item md={7} xs={12} sm={12}>
                      <div className={classes.webinarBoxMb}>
                        <p className={classes.titleMb}>¡WEBINAR EN VIVO!</p>
                        <p className={classes.webinarTextMb}>
                          <b className={classes.webinarTitle}>"{ongoingWebinar.title}"</b> se está
                          transmitiendo ahora mismo.
                        </p>
                      </div>
                    </Grid>
                    <Grid item md={3} xs={12} sm={12} className={classes.buttonSectionMb}>
                      <button
                        type="button"
                        className={classes.redButtonMb}
                        onClick={e => {
                          e.preventDefault()
                          window.open(`/webinars/${ongoingWebinar.id}`)
                        }}
                      >
                        <p>
                          <b>VER WEBINAR</b> <i className={classes.arrowRight}></i>
                        </p>
                      </button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Container>
          </Fade>
        </Modal>
      </>
    )

  return (
    <React.Fragment>
      {ongoingWebinar !== undefined && (
        <span className={classes.root}>
          <Container className={classes.container}>
            <Grid container alignItems="center" className={classes.navBarWrapper}>
              <Grid container alignItems="center" className={classes.navBarRow}>
                <CloseIcon className={classes.closebtnWeb} onClick={handleClose} />
              </Grid>
              <Grid item md={2} xs={12} sm={12}>
                <div className={classes.iconRoot}>
                  <img src={icon} className={classes.icon} alt="Icono" />
                </div>
              </Grid>
              <Grid item md={7} xs={12} sm={12}>
                <div className={classes.webinarBox}>
                  <p className={classes.title}>¡WEBINAR EN VIVO!</p>
                  <p className={classes.webinarText}>
                    <b className={classes.webinarTitle}>"{ongoingWebinar.title}"</b> se está
                    transmitiendo ahora mismo.
                  </p>
                </div>
              </Grid>
              <Grid item md={3} xs={12} sm={12} className={classes.buttonSection}>
                <button
                  type="button"
                  className={classes.redButton}
                  onClick={e => {
                    e.preventDefault()
                    window.open(`/webinars/${ongoingWebinar.id}`)
                  }}
                >
                  <p>
                    <b>VER WEBINAR</b> <i className={classes.arrowRight}></i>
                  </p>
                </button>
              </Grid>
            </Grid>
          </Container>
        </span>
      )}
    </React.Fragment>
  )
}

export { ModalLastWebinar }
