import React, { FC, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal, TextField, Typography } from '@material-ui/core'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AuthContext, LoginContext } from '@contexts'
import { useGlobalStyles } from '@styles'
import { useAuth } from '@hooks'

import { useStyles } from './styles'
import { validationSchema } from './validations'
import { LoginFormType } from './types'
import { FormAlert } from '../FormAlert'

interface Props {
  isOpen: boolean
}

const defaultValues = {
  email: '',
  password: '',
}

const ModalLogin: FC<Props> = () => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  const { isOpen, handleClose, setIsOpen } = useContext(LoginContext)
  const { isAuthenticated } = useContext(AuthContext)

  const { login, errorMessage, successMessage, setErrorMessage } = useAuth()

  const { control, handleSubmit, errors } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const onSubmit: SubmitHandler<LoginFormType> = data => {
    login(data).then(() => {
      if (isAuthenticated) setIsOpen(false)
    })
  }

  useEffect(() => {
    if (isAuthenticated) setTimeout(() => setIsOpen(false), 0) // 1500
  }, [isAuthenticated, setIsOpen])

  return (
    <Modal
      open={!!isOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={`${globalClasses.modal} ${classes.modal}`}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classes.contentWrapper}
        onChange={() => setErrorMessage('')}
      >
        <Typography component="p">Ingresá a la plataforma con tus credenciales.</Typography>
        <Typography component="p">
          Si aún no tenés cuenta o si aún no estás registrado en Takeda ID. Haz click en '¿Aún no tenés cuenta?'
        </Typography>
        <Controller
          name="email"
          control={control}
          as={
            <TextField
              label="Correo electrónico"
              placeholder="Ingresar correo electrónico"
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              autoComplete="off"
              size="small"
              autoFocus={true}
            />
          }
        />
        {errors.email && <FormAlert>{errors.email.message}</FormAlert>}
        <Controller
          name="password"
          control={control}
          as={
            <TextField
              type="password"
              label="Contraseña"
              placeholder="Ingresar Contraseña"
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              autoComplete="off"
              size="small"
            />
          }
        />
        {errors.password && <FormAlert>{errors.password.message}</FormAlert>}
        {successMessage ||
          (errorMessage && (
            <div className={`${globalClasses.textCenter} ${globalClasses.mediumTopSeparator}`}>
              <FormAlert type={successMessage ? 'textSuccess' : 'textError'}>
                {successMessage || errorMessage}
              </FormAlert>
            </div>
          ))}
        <Button type="submit" variant="contained" color="primary" className={classes.button}>
          Iniciar Sesión
        </Button>
        <Link to="/recovery-password" className={classes.link}>
          ¿Olvidaste tu contraseña?
        </Link>
        <Link to="/sign-up" className={classes.signUpButton}>
          ¿Aún no tenés cuenta?
        </Link>
      </form>
    </Modal>
  )
}

export { ModalLogin }
