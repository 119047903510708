import React, { FC } from 'react'
import { useQuery } from 'react-query'
import ScrollContainer from 'react-indiana-drag-scroll'
import { RouteComponentProps } from 'react-router'

import { QUERY_KEYS, WebinarsGlobalApi } from '@api'
import { Header, Layout, Main } from '@components'
import headerBackground from '@assets/images/thrapeutic-areas-bg.jpg'

import { CARD, SCROLL_WRAPPER } from '../constants'
import { WebinarCard } from '../components'
import { useStyles } from './styles'

export const TherapeuticAreas: FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const classes = useStyles()

  const { data } = useQuery(
    [QUERY_KEYS.WEBINARS, { therapeuticAreaId: Number(match.params.id), isSalient: true }],
    WebinarsGlobalApi.getWebinars,
  )

  const gridContainerWidth = (count: number) => {
    const oneItem = CARD.HUGE.WIDTH
    const twoItems = oneItem + CARD.BIG.WIDTH + SCROLL_WRAPPER.SPACING.HORIZONTAL
    const threeOrMoteItems =
      twoItems +
      SCROLL_WRAPPER.SPACING.HORIZONTAL +
      Math.ceil((count - 2) / 2) * (CARD.SMALL.WIDTH + SCROLL_WRAPPER.SPACING.HORIZONTAL)

    switch (count) {
      case 1:
        return oneItem
      case 2:
        return twoItems
      default:
        return threeOrMoteItems
    }
  }

  return (
    <Layout>
      <Main showGoBack backgroundImage={headerBackground} className={classes.main}>
        <Header
          title={data?.therapeuticArea.name}
          titleColor={data?.therapeuticArea.color}
          className={classes.header}
          titleClass={classes.headerTitle}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        />
        {data && (
          <ScrollContainer className={`scroll-container ${classes.scorller}`}>
            <div
              className={classes.gridContainer}
              style={{
                width: `${gridContainerWidth(data.webinars.length)}vw`,
              }}
            >
              {data.webinars.map((webinar, i) => (
                <WebinarCard
                  key={webinar.title}
                  webinar={webinar}
                  size={(i === 0 && 'huge') || (i === 1 && 'big') || 'small'}
                  position={i}
                />
              ))}
            </div>
          </ScrollContainer>
        )}
      </Main>
    </Layout>
  )
}
