import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    top: '50% !important',
    left: '50% !important',
    transform: 'translate(-50%, -50%) !important',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: '10px',
    right: '15px',
  },
  modalCard: {
    width: 1315,
    position: 'relative',
  },
  modalContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 10,
    backgroundColor: '#333',
    borderRadius: '50%',
    width: 30,
    height: 30,
    minWidth: 'unset',
    '&:hover': {
      backgroundColor: '#999',
    },
  },
  modalCloseIcon: {
    position: 'unset',
    color: 'white',
  },
}))

export { useStyles }
