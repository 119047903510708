import React, { FC } from 'react'
import { Container, Box, Typography } from '@material-ui/core'

import { useStyles } from './styles'

interface Props {
  className?: string
  title?: string
  titleColor?: string
  titleClass?: string
  height?: number
  display?: string
  alignItems?: string
  flexDirection?: string
  justifyContent?: string
}

export const Header: FC<Props> = ({
  children,
  className,
  title,
  titleColor,
  titleClass,
  height,
  display = 'flex',
  alignItems = 'flex-start',
  justifyContent = 'center',
  flexDirection = 'column',
}) => {
  const classes = useStyles()

  return (
    <header className={`${className} ${classes.header}`} style={{ height }}>
      <Container>
        <Box
          display={display}
          flexDirection={flexDirection}
          alignItems={alignItems}
          justifyContent={justifyContent}
        >
          {title && (
            <Typography
              variant="h2"
              color="textPrimary"
              className={`${titleClass} ${classes.title}`}
              style={{ color: titleColor }}
            >
              {title}
            </Typography>
          )}
          {children}
        </Box>
      </Container>
    </header>
  )
}
