import React, { createContext, Dispatch, FC, SetStateAction } from 'react'
import { useHistory } from 'react-router-dom'

import { ModalLogin } from '@components'

type ContextType = {
  isOpen?: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  handleClose: () => void
}

const LoginContext = createContext<ContextType>({ setIsOpen: () => false, handleClose: () => null })

const LoginProvider: FC = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const handleClose = () => setIsOpen(false)

  const { listen } = useHistory()

  listen(() => {
    setIsOpen(false)
  })

  return (
    <LoginContext.Provider value={{ isOpen, setIsOpen, handleClose }}>
      <ModalLogin isOpen={isOpen} />
      {children}
    </LoginContext.Provider>
  )
}

export { LoginProvider, LoginContext }
