import React, { FC } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { InputAdornment, TextField } from '@material-ui/core'
import { useHistory } from 'react-router'

export const Search: FC = () => {
  const params = new URLSearchParams(window.location.search)

  const { push } = useHistory()

  return (
    <>
      <TextField
        placeholder="FILTRAR SPEAKERS"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={e => {
          params.set('search', e.target.value)
          push({
            pathname: '/speakers',
            search: `?${params.toString()}`,
          })
        }}
      />
    </>
  )
}
