import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  header: {
    marginBottom: 0,
  },
  container: {
    paddingLeft: 60,
    paddingRight: 60,
  },
})

export { useStyles }
