import React, { createContext, Dispatch, FC, SetStateAction, useState } from 'react'

import { UserCache } from '@types'

type ContextType = {
  isAuthenticated?: boolean
  setIsAuthenticated: Dispatch<SetStateAction<boolean | undefined>>
  userLoggedIn?: UserCache | null
  setUserLoggedIn: Dispatch<SetStateAction<UserCache | undefined>>
}

const AuthContext = createContext<ContextType>({
  isAuthenticated: undefined,
  userLoggedIn: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsAuthenticated: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUserLoggedIn: () => {},
})

const AuthProvider: FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(false)
  const [userLoggedIn, setUserLoggedIn] = useState<UserCache | undefined>()

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, userLoggedIn, setIsAuthenticated, setUserLoggedIn }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider, AuthContext }
