import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  modalCard: {
    width: 500,
    position: 'relative',
  },
})

export { useStyles }
