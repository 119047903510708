import { api } from '@utils'

import { GetWebinarResponse } from './types'

const getWebinar = async (_: string, id: number) => {
  const response = await api.get<GetWebinarResponse>(`/webinars/${id}`)
  return response.data.webinar
}

export const WebinarDetailApi = { getWebinar }
