import React, { FC, useMemo } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { InputAdornment, TextField } from '@material-ui/core'
import { NavLink, useHistory, useLocation } from 'react-router-dom'

import { useStyles } from './styles'

export const Search: FC = () => {
  const classes = useStyles()
  const { search } = useLocation()
  const { push } = useHistory()

  const params = useMemo(() => new URLSearchParams(search), [search])

  return (
    <div className={classes.search}>
      <NavLink
        to={() => {
          params.delete('time')
          return {
            pathname: '/webinars',
            search: `?${params.toString()}`,
          }
        }}
        className={classes.navLink}
        isActive={() => {
          const p = new URLSearchParams(search)
          return !p.has('time')
        }}
        exact
      >
        TODOS
      </NavLink>
      <NavLink
        to={() => {
          return {
            pathname: '/webinars',
            search: `?${params.toString()}&time=past`,
          }
        }}
        className={classes.navLink}
        isActive={() => {
          const p = new URLSearchParams(search)
          return p.get('time') === 'past'
        }}
        exact
      >
        ANTERIORES
      </NavLink>
      <NavLink
        to={() => {
          // params.set('time', 'future')
          return {
            pathname: '/webinars',
            search: `?${params.toString()}&time=future`,
          }
        }}
        className={classes.navLink}
        isActive={() => {
          const p = new URLSearchParams(search)
          return p.get('time') === 'future'
        }}
        exact
      >
        PRÓXIMOS
      </NavLink>
      <TextField
        placeholder="FILTRAR WEBINARS"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        defaultValue={params.get('search') ?? params.get('tagName') ?? params.get('speakerName')}
        className={classes.searchInput}
        onChange={e => {
          params.set('search', e.target.value)
          push({
            pathname: '/webinars',
            search: `?${params.toString()}`,
          })
        }}
      />
    </div>
  )
}
