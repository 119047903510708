import React, { FC } from 'react'
import { Modal, Fade, Container, Card, CardContent, Button } from '@material-ui/core'
import ReactPlayerLoader from '@brightcove/react-player-loader'
import CloseIcon from '@material-ui/icons/Close'
import { useGlobalStyles } from '@styles'
import { Webinar } from '@types'
import { config } from '@config'

import { useStyles } from './styles'

interface Props {
  isOpen: boolean
  webinarSelected: Webinar
  handleClose: () => void
}

export const ViewVideoWebinarModal: FC<Props> = ({ isOpen, webinarSelected, handleClose }) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const { accountId, playerId } = config

  return (
    <>
      <Modal open={isOpen} onClose={handleClose} className={globalClasses.modal}>
        <Fade in={isOpen} timeout={500}>
          <Container
            style={{ display: 'flex', alignItems: 'center', height: '100vh', outline: 'none' }}
          >
            <Card className={classes.modalCard}>
              <Button className={classes.closeButton} onClick={handleClose}>
                <CloseIcon
                  className={`${globalClasses.modalCloseIcon} ${classes.modalCloseIcon}`}
                />
              </Button>
              {webinarSelected && (
                <CardContent className={classes.modalContent}>
                  <ReactPlayerLoader
                    accountId={accountId!}
                    playerId={playerId}
                    videoId={webinarSelected.videoId}
                    options={{ fluid: true, responsive: true }}
                  />
                </CardContent>
              )}
            </Card>
          </Container>
        </Fade>
      </Modal>
    </>
  )
}
