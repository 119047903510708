import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  header: {
    marginBottom: 28,
    paddingTop: 28,
    paddingBottom: 28,
  },
  title: {
    fontSize: '3.2vh',
    fontWeight: 700,
    marginBottom: 14,
  },
  btnBack: {
    color: '#FFF',
    marginBottom: 'auto',
  },
})

export { useStyles }
