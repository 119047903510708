import { makeStyles } from '@material-ui/core'
import { COLORS } from '@styles'

const MAIN_HEADER_HEIGHT = 386

const useStyles = makeStyles(theme => ({
  header: {
    position: 'relative',
    height: MAIN_HEADER_HEIGHT,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      height: MAIN_HEADER_HEIGHT - 20,
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: MAIN_HEADER_HEIGHT,
    // maxWidth: '70%',
    fontSize: 43,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 700,
    margin: '0 auto',
    padding: 60,
    color: COLORS.GRAY_HEADER,
    [theme.breakpoints.down('sm')]: {
      height: MAIN_HEADER_HEIGHT - 50,
      maxWidth: '90%',
      padding: 10,
      fontSize: 24,
    },
  },
  article: {
    display: 'flex',
    paddingBottom: 40,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  articleMain: {
    flex: 3,
    flexDirection: 'column',
    marginTop: -40,
    zIndex: 1,
  },
  date: {
    backgroundColor: COLORS.GRAY_DARK,
    display: 'inline-block',
    fontSize: '1.26rem',
    color: COLORS.WHITE,
    marginBottom: 22,
    padding: '10px 30px',
    borderRadius: '0 20px 20px',
  },
  duration: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 26,
    '& > span': {
      marginLeft: 10,
    },
  },
  tags: {
    marginBottom: 36,
    marginTop: 30,
  },
  tag: {
    display: 'inline-block',
    marginBottom: 7,
    fontSize: 14,
    fontWeight: 500,
    padding: '5px 9px',
    border: '2px solid',
    borderRadius: 20,
    '&:nth-child(n + 2)': {
      marginLeft: 10,
    },
    color: COLORS.GRAY_HEADER,
    cursor: 'pointer',
  },
  description: {
    fontSize: '1rem',
    width: '90%',
    fontWeight: 300,
  },
  aside: {
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 100,
    },
  },
  mobileButton: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export { useStyles }
