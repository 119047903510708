import React, { FC } from 'react'
import { Container, useMediaQuery, useTheme } from '@material-ui/core'
import { RouteComponentProps, useHistory } from 'react-router'
import { useQuery } from 'react-query'
import parse from 'html-react-parser'
import moment from 'moment'
import headerBackground from '@assets/images/detalle-webinar.jpg'
import { QUERY_KEYS } from '@api'
import { Header, Layout, Main } from '@components'
import { SHORT_MONTH_AND_YEAR, FRIENDLY_DATE_WITH_HS } from '@constants'

import { WebinarDetailApi } from './api'
import { useStyles } from './styles'
import { Aside } from './components'
import { AccessToWebinarButton } from './components/Aside/components'

const headTitle = ' - Espacio Oncología / TAKEDA'
const headDescription =
  'Charlas, actividades y eventos de actualización científica enfocada en oncología. Mieloma Múltiple - Linfoma - Cancer de Pulmón.'

export const WebinarDetails: FC<RouteComponentProps<{ id: string }>> = ({
  match,
}: RouteComponentProps<{ id: string }>) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { push } = useHistory()

  const { id } = match!.params

  const { data: webinar } = useQuery([QUERY_KEYS.WEBINARS, id], WebinarDetailApi.getWebinar)

  return (
    <Layout headTitle={`${webinar?.title} ${headTitle}`} headDescription={headDescription}>
      <Main showGoBack backgroundImage={headerBackground}>
        <Header className={classes.header} title={webinar?.title} titleClass={classes.title} />

        <Container>
          <article className={`speaker ${classes.article}`}>
            <div className={classes.articleMain}>
              <div className={classes.date}>
                {moment(webinar?.date)
                  .locale('es')
                  .format(
                    moment().utcOffset(-3).isAfter(webinar?.date)
                      ? SHORT_MONTH_AND_YEAR
                      : FRIENDLY_DATE_WITH_HS,
                  )}
              </div>

              {webinar && isMobile && (
                <div className={classes.mobileButton}>
                  <AccessToWebinarButton webinar={webinar} />
                </div>
              )}

              {webinar?.description && (
                <div className={classes.description}>{parse(webinar.description)}</div>
              )}

              {webinar?.tags && (
                <div className={classes.tags}>
                  {webinar.tags.map(tag => (
                    <button
                      className={classes.tag}
                      key={tag.name}
                      onClick={() => push(`/webinars?tagName=${tag.name}`)}
                      type="button"
                    >
                      {tag.name}
                    </button>
                  ))}
                </div>
              )}
            </div>
            {webinar && (
              <Aside
                className={classes.aside}
                speakers={webinar?.speakers ?? []}
                webinar={webinar}
              />
            )}
          </article>
        </Container>
      </Main>
    </Layout>
  )
}
