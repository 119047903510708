import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: 30,
  },
  logo: {
    marginBottom: 40,
  },
  header: {
    width: '100%',
    marginBottom: 0,
  },
  contentWrapper: {
    width: '100%',
    maxWidth: 628,
    margin: '0 auto',
    padding: 26,
    borderRadius: 35,
    boxShadow: '5px 5px 50px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#FFF',
  },
  button: {
    width: 200,
    margin: '20px auto',
    display: 'block',
  },
  labelForm: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginLeft: 12,
    marginBottom: 5,
  },
  textAreaForm: {
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderRadius: 30,
    padding: '15px 12px',
    /* '&:focus-visible': {
      borderColor: '#ff0202',
      borderWidth: 2,
    }, */
    '&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline': {
      borderColor: 'red', // default
    },
    '&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline': {
      borderColor: 'blue', // hovered
    },
    '&$cssFocused $notchedOutline': {
      borderColor: 'purple', // focused
    },
  },
  cssFocused: {},

  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'green !important',
  },
  textfieldPara: {
    marginLeft: 7,
    backgroundColor: '#eeeeee',
    padding: 10,
    '& .MuiInput-underline': {
      '&:before': {
        borderBottom: 'none !important',
        position: 'fixed !important',
      },
    },

    '& .MuiInputBase-input': {
      padding: '0 !important',
    },
  },
  colorInput: {
    '& .MuiInput-underline': {
      '&:before': {
        borderBottom: 'none !important',
        position: 'fixed !important',
      },
    },

    '& .MuiInputBase-input': {
      color: '#aaaaaa',
    },
  },
})

export { useStyles }
