import { useContext, useEffect, useState } from 'react'
import moment from 'moment'

import { AuthContext } from '@contexts'
import {
  Registrant,
  Webinar,
  WebinarStatus,
  WeibarActionsButton,
  TextWebinarActionButton,
} from '@types'

/**
 * TODO - Este Hook requiere de un refactor completo xq se hicieron cambios funcionales
 *        de último momento que lo dejaron muy desprolijo.
 */

const useWebinarActionsButton = ({
  registrants,
  status,
  videoId: webinarHasVideo,
  date,
  duration
}: Webinar) => {
  const [textButton, setTextButton] = useState('Accedé')
  const [registrantUser, setRegistrantUser] = useState<Registrant | undefined>()
  const [buttonAction, setButtonAction] = useState<string | boolean>('')
  const { userLoggedIn, isAuthenticated } = useContext(AuthContext)

  useEffect(() => {
    const itIsRegistered = () =>
      registrants.find(registrant => registrant.email === userLoggedIn?.email)
    const finishDate = moment(date).add(duration,'minutes')
    const isWebinarOver = () => moment().utcOffset(-3).isAfter(finishDate)

    if (!isAuthenticated) {
      if (isWebinarOver() && status === WebinarStatus.completed) {
        setTextButton('')
        setButtonAction(false)
      } else {
        setTextButton(TextWebinarActionButton.access)
        setButtonAction(WeibarActionsButton.register)
      }
    } else {
      switch (status) {
        case WebinarStatus.finalized:
          if (webinarHasVideo) {
            setTextButton(TextWebinarActionButton.showVideo)
            setButtonAction(WeibarActionsButton.showVideo)
          } else {
            setTextButton(TextWebinarActionButton.soonVideo)
            setButtonAction(WeibarActionsButton.disabled)
          }
          break

        case WebinarStatus.started:
          if (itIsRegistered()) {
            setTextButton(TextWebinarActionButton.watchLive)
            setRegistrantUser(itIsRegistered())
            setButtonAction(WeibarActionsButton.goToWebinar)
          } else {
            setTextButton(TextWebinarActionButton.access)
            setButtonAction(WeibarActionsButton.register)
          }
          break

        case WebinarStatus.completed:
          if (isWebinarOver()) {
            setTextButton('')
            setButtonAction(false)
          } else if (itIsRegistered()) {
            setTextButton(TextWebinarActionButton.registered)
            setButtonAction(WeibarActionsButton.disabled)
          } else if (!isWebinarOver()) {
            setTextButton(TextWebinarActionButton.access)
            setButtonAction(WeibarActionsButton.register)
          } else if (webinarHasVideo) {
            setTextButton(TextWebinarActionButton.showVideo)
            setButtonAction(WeibarActionsButton.showVideo)
          } else {
            setTextButton(TextWebinarActionButton.showVideo)
            setButtonAction(WeibarActionsButton.disabled)
          }
          break

        default:
          if (itIsRegistered()) {
            setTextButton(TextWebinarActionButton.registered)
            setButtonAction(WeibarActionsButton.disabled)
          } else if (!isWebinarOver()) {
            setTextButton(TextWebinarActionButton.access)
            setButtonAction(WeibarActionsButton.register)
          } else if (webinarHasVideo) {
            setTextButton(TextWebinarActionButton.showVideo)
            setButtonAction(WeibarActionsButton.showVideo)
          } else {
            setTextButton(TextWebinarActionButton.soonVideo)
            setButtonAction(WeibarActionsButton.disabled)
          }
      }
    }
  }, [date, isAuthenticated, registrants, status, userLoggedIn, webinarHasVideo])

  return { textButton, buttonAction, registrantUser }
}

export { useWebinarActionsButton }
