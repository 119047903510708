import React, { FC, useContext, useState } from 'react'
import * as momentTimeZone from 'moment-timezone'
import moment from 'moment'
import { queryCache, useMutation } from 'react-query'
import { Button, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import VideoCallIcon from '@material-ui/icons/VideoCall'
import { QUERY_KEYS } from '@api'
import { ModalAlert } from '@components'
import { FRIENDLY_DATE_WITH_HS } from '@constants'
import { AuthContext, BackdropContext, LoginContext } from '@contexts'
import { useWebinarActionsButton } from '@hooks'
import { TextWebinarActionButton, Webinar, WeibarActionsButton } from '@types'
import Arrow from '@assets/images/shape.png'

import { ViewVideoWebinarModal } from './components'
import { WebinarApi } from './api'
import { useStyles } from './styles'

interface Props {
  webinar: Webinar
}

export const AccessToWebinarButton: FC<Props> = ({ webinar }) => {
  const [isViewVideoWebinarModalOpen, setIsViewVideoWebinarModalOpen] = useState(false)
  const [isModalAlertOpen, setIsModalAlertOpen] = useState(false)

  const classes = useStyles()

  const { userLoggedIn } = useContext(AuthContext)
  const { setIsOpen } = useContext(LoginContext)
  const { setBackdropIsOpen } = useContext(BackdropContext)

  const { textButton, buttonAction, registrantUser } = useWebinarActionsButton(webinar)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [add] = useMutation(WebinarApi.addRegistrant, {
    onMutate: () => {
      setBackdropIsOpen(true)
    },
    onSuccess: async () => {
      queryCache.invalidateQueries(QUERY_KEYS.WEBINARS)
      setBackdropIsOpen(false)
    },
    onError: () => {
      setBackdropIsOpen(false)
      // TODO
    },
  })

  const handleActionClick = async (action: string | boolean) => {
    switch (action) {
      case WeibarActionsButton.register:
        if (userLoggedIn) {
          const response = await add({
            webinarId: webinar.id,
            registrant: userLoggedIn,
            timeZone: momentTimeZone.tz.guess(),
          })
          if (response === 'OK') setIsModalAlertOpen(true)
        } else {
          setIsOpen(true)
        }
        break
      case WeibarActionsButton.goToWebinar:
        window.location.href = registrantUser!.joinUrl
        break
      case WeibarActionsButton.showVideo:
        setIsViewVideoWebinarModalOpen(true)
        break
      case WeibarActionsButton.disabled:
        break
      default:
        break
    }
  }

  if (textButton === '') return null

  return (
    <>
      {isMobile && (
        <>
          {textButton === TextWebinarActionButton.soonVideo ||
          textButton === TextWebinarActionButton.registered ? (
            <div
              style={{ fontSize: '1rem', fontWeight: 800, textAlign: 'center', color: '#535353' }}
            >
              {textButton}
            </div>
          ) : (
            <Button
              className={classes.button}
              style={{
                color: webinar?.therapeuticArea.color,
                border: `2px solid ${webinar?.therapeuticArea.color}`,
              }}
              onClick={() => handleActionClick(buttonAction)}
              disabled={buttonAction === WeibarActionsButton.disabled}
            >
              {textButton}
              {textButton === TextWebinarActionButton.watchLive ? (
                <VideoCallIcon fontSize="large" className={classes.buttonIcon} />
              ) : (
                <ArrowRightIcon fontSize="large" className={classes.buttonIcon} />
              )}
            </Button>
          )}
        </>
      )}

      {!isMobile && (
        <>
          {textButton === TextWebinarActionButton.soonVideo ||
          textButton === TextWebinarActionButton.registered ? (
            <div
              style={{ fontSize: '1rem', fontWeight: 800, textAlign: 'center', color: '#535353' }}
            >
              {textButton}
            </div>
          ) : (
            <Button
              className={classes.button}
              style={{ backgroundColor: webinar?.therapeuticArea.color }}
              onClick={() => handleActionClick(buttonAction)}
              disabled={buttonAction === WeibarActionsButton.disabled}
            >
              {textButton} <img src={Arrow} alt="arrow" className={classes.buttonIcon} />
            </Button>
          )}
        </>
      )}

      <ViewVideoWebinarModal
        isOpen={isViewVideoWebinarModalOpen}
        webinarSelected={webinar!}
        handleClose={() => setIsViewVideoWebinarModalOpen(false)}
      />

      <ModalAlert
        isOpen={isModalAlertOpen}
        setIsOpen={setIsModalAlertOpen}
        handleAcceptClick={() => setIsModalAlertOpen(false)}
      >
        <Typography component="h2" variant="h2" align="center">
          Registro Confirmado
        </Typography>
        <Typography component="p" paragraph align="center">
          Ya tenés tu lugar confirmado para esta actividad.
        </Typography>
        <Typography component="h3" variant="h3" align="center">
          {webinar.title}
        </Typography>
        <Typography component="p" paragraph align="center">
          {moment(webinar?.date).format(FRIENDLY_DATE_WITH_HS)}
        </Typography>
        <div>
          Speakers:
          <Typography component="ul">
            {webinar.speakers.map(speaker => (
              <Typography component="li" key={speaker.id}>
                {speaker.lastName}, {speaker.name}
              </Typography>
            ))}
          </Typography>
        </div>
      </ModalAlert>
    </>
  )
}
