import { api } from '@utils'

import { GetSpeakersResponse } from './types'

interface Payload {
  offset: number
  limit: number
  name: string
  lastName: string
  specialty: string
  therapeuticAreaId?: number
}

const getSpeakers = async (
  _: string,
  { offset = 0, limit = 10, name, lastName, specialty, therapeuticAreaId }: Payload,
): Promise<GetSpeakersResponse> => {
  const params = {
    name: name || undefined,
    lastName: lastName || undefined,
    specialty: specialty || undefined,
    therapeuticAreaId: therapeuticAreaId || undefined,
    offset: offset * limit,
    limit,
  }
  const response = await api.get<GetSpeakersResponse>('/speakers', { params })

  return response.data
}

export const SpeakersApi = { getSpeakers }
