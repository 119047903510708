export const QUERY_KEYS = {
  USER: 'user',
  SESSION_TOKEN: 'sessionToken',
  ID_TOKEN: 'idToken',
  SPEAKERS: 'speakers',
  WEBINARS: 'webinars',
  HOME_CAROUSEL_ITEMS: 'homeCarouselItems',
  THERAPEUTIC_AREAS: 'therapeuticAreas',
  ONGOING_WEBINAR: 'ongoingWebinar',
}
