import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    top: '50% !important',
    left: '50% !important',
    transform: 'translate(-50%, -50%) !important',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: '10px',
    right: '15px',
  },
  modalCard: {
    width: 1315,
    position: 'relative',
  },
  modalContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  header: {
    width: '100%',
    fontSize: '3rem',
    padding: 0,
    marginBottom: 0,
    color: '#B0B0B0',
    textAlign: 'center',
  },
  divider: {
    marginBottom: 20,
    backgroundColor: '#FC1622',
  },
  list: {
    width: '100%',
    maxWidth: 360,
  },
  listItem: {
    paddingTop: 'unset',
    paddingBottom: 'unset',
    '& > div': {
      padding: 0,
    },
  },
}))

export { useStyles }
