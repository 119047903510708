import React, { FC } from 'react'
import {
  Container,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Button,
} from '@material-ui/core'
import { RouteComponentProps, useHistory } from 'react-router'
import { useQuery } from 'react-query'
import parse from 'html-react-parser'

import { QUERY_KEYS, SpeakersGlobalApi } from '@api'
import { Layout, Main } from '@components'
import headerBackground from '@assets/images/speakers-banner.jpg'

import { useStyle } from './styles'

export const SpeakerDetails: FC<RouteComponentProps<{ id: string }>> = ({
  match,
}: RouteComponentProps<{ id: string }>) => {
  const classes = useStyle()
  const { push } = useHistory()

  const { id } = match!.params

  const { data: speaker } = useQuery([QUERY_KEYS.SPEAKERS, id], SpeakersGlobalApi.getSpeaker)

  return (
    <Layout>
      <Main showGoBack backgroundImage={headerBackground}>
        <Container className={classes.container}>
          <Card className={classes.card}>
            {!speaker ? (
              <CardContent className={classes.spinner}>
                <CircularProgress />
              </CardContent>
            ) : (
              <>
                <CardHeader
                  className={classes.header}
                  avatar={<Avatar src={speaker?.profilePictureUrl} className={classes.avatar} />}
                  title={
                    <span className={classes.name}>{`${speaker?.lastName}, ${speaker?.name}`}</span>
                  }
                  subheader={
                    <span
                      className={classes.specialty}
                    >{`${speaker?.specialty} / ${speaker?.country}`}</span>
                  }
                />
                <CardContent className={classes.content}>
                  <>
                    {speaker?.biography && (
                      <span className={classes.biography}>{parse(speaker.biography)}</span>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ display: 'block', margin: 'auto' }}
                      onClick={() => push(`/webinars?speakerID=${speaker.id}`)}
                    >
                      Ver Webinars de {speaker.lastName}, {speaker.name}
                    </Button>
                  </>
                </CardContent>
              </>
            )}
          </Card>
        </Container>
      </Main>
    </Layout>
  )
}
