import React, { FC, useState } from 'react'
import { Backdrop, Container, Typography } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import { HomeCarouselItem } from '@types'

import { StyleProps, useStyles } from './styles'

interface Props {
  carouselItem: HomeCarouselItem
}

const Item: FC<Props> = ({ carouselItem: { title, link, description, imageUrl } }) => {
  const props: StyleProps = {
    urlString: imageUrl,
  }
  const classes = useStyles(props)

  const [isLoading, setIsLoading] = useState(true)

  return (
    <div className={classes.item}>
      <Backdrop open={isLoading} onClick={() => setIsLoading(false)}>
        {/* <CircularProgress color="inherit" /> */}
      </Backdrop>

      <Container className={classes.itemWrapper}>
        <div className={classes.flexChild}>
          {title && (
            <Typography
              variant="h2"
              className={classes.title}
              style={{ marginBottom: description ? 10 : 0 }}
            >
              {title}
            </Typography>
          )}

          {description && (
            <Typography className={classes.description} style={{ marginBottom: link ? 20 : 0 }}>
              {description}
            </Typography>
          )}
        </div>
        {link && (
          <a className={classes.link} href={link} target="_blank" rel="noopener noreferrer">
            Ver más
            <ChevronRight />
          </a>
        )}
      </Container>
    </div>
  )
}

export { Item }
