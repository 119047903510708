import { useContext, useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'

import { BackdropContext } from '@contexts'
import { ApiResponse } from '@types'

import { useErrorHandler } from '../useErrorHandler'
import { AccountApi } from './api'
import { ResetPasswordPayload } from './types'

const useAccount = () => {
  const [successMessage, setSuccessMessage] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)

  const { push } = useHistory()

  const { setBackdropIsOpen } = useContext(BackdropContext)

  const { setErrorMessage, errorMessage } = useErrorHandler()

  const [resetAccountPassword] = useMutation(AccountApi.resetPassword, {
    onMutate: () => setBackdropIsOpen(true),
    onSuccess: () => {
      setSuccessMessage('Contraseña cambiada con éxito. Redireccionando...')
      setBackdropIsOpen(false)
      setIsSuccess(true)
      setTimeout(() => {
        push('/')
      }, 3000)
    },
    onError: ({ type, field }: ApiResponse) => {
      setBackdropIsOpen(false)
      setErrorMessage(type, field)
    },
  })

  const [verifyAccountToken] = useMutation(AccountApi.verifyToken, {
    onMutate: () => setBackdropIsOpen(true),
    onSuccess: () => {
      setBackdropIsOpen(false)
      // TODO
    },
    onError: () => {
      setBackdropIsOpen(false)
      // TODO
    },
  })

  const [recoveryAccountPassword] = useMutation(AccountApi.recoveryPassword, {
    onMutate: () => {
      setBackdropIsOpen(true)
    },
    onSuccess: () => {
      setIsSuccess(true)
      setBackdropIsOpen(false)
    },
    onError: ({ type, field }: ApiResponse) => {
      setBackdropIsOpen(false)
      setErrorMessage(type, field)
    },
  })

  const resetPassword = (data: ResetPasswordPayload) => {
    resetAccountPassword(data)
  }

  const verifyToken = async (data: {
    recoveryToken: string | null
  }): Promise<string | undefined> => {
    const response = await verifyAccountToken(data)
    return response
  }

  const recoveryPassword = (data: { email: string }) => {
    recoveryAccountPassword(data)
  }

  return { resetPassword, verifyToken, recoveryPassword, successMessage, errorMessage, isSuccess }
}

export { useAccount }
