import React, { FC, useEffect, useState, useContext } from 'react'
import { Container, Grid } from '@material-ui/core'
import { useQuery } from 'react-query'
import InfiniteScroll from 'react-infinite-scroller'
import { useLocation } from 'react-router'

import { QUERY_KEYS, WebinarsGlobalApi } from '@api'
import { Header, Layout, Main, ModalLastWebinar } from '@components'
import { Webinar } from '@types'
import headerBackground from '@assets/images/webinars-banner.jpg'

import { WebinarCard, NavFilter } from '../components'
import { useStyles } from './styles'
import { AppliedFilters } from './components'
import { OngoingWebinarContext } from '@contexts'

const headTitle = 'Actividades - Espacio Oncología / TAKEDA'
const headDescription =
  'Charlas, actividades y eventos de actualización científica enfocada en oncología. Mieloma Múltiple - Linfoma - Cancer de Pulmón.'

export const Webinars: FC = () => {
  const classes = useStyles()

  const { pathname, search } = useLocation()

  const params = new URLSearchParams(window.location.search)

  const [offset, setOffset] = useState(0)
  const [limit] = useState(10)
  const [webinars, setWebinars] = useState<Webinar[]>([])
  const [webinarsCount, setWebinarsCount] = useState(0)

  const [lockLoadItems, setLockLoadItems] = useState(false)
  const [currentPath, setCurrentPath] = useState('')

  useQuery(
    [
      QUERY_KEYS.WEBINARS,
      {
        offset,
        limit,
        title: params.get('search'),
        speakerName: params.get('speakerName') ?? params.get('search'),
        speakerID: params.get('speakerID')!,
        tagName: params.get('tagName') ?? params.get('search'),
        therapeuticAreaId: params.get('therapeutic-area'),
        pastWebinars: params.get('time') === 'past',
        nextWebinars: params.get('time') === 'future',
      },
    ],
    WebinarsGlobalApi.getWebinars,
    {
      onSuccess: ({ count, webinars: webinarData }) => {
        setWebinarsCount(count)
        setWebinars(prevState => [...prevState, ...webinarData])
        setLockLoadItems(false)
      },
      refetchOnWindowFocus: false,
    },
  )

  const loadItems = () => {
    if (!lockLoadItems) {
      setLockLoadItems(true)
      setOffset(prevState => prevState + 1)
    }
  }

  useEffect(() => {
    if (`${pathname}${search}` !== currentPath) {
      if (offset !== 0) setOffset(0)
      if (webinars.length > 0) setWebinars([])
      setCurrentPath(`${pathname}${search}`)
    }
  }, [currentPath, offset, pathname, search, webinars.length])

  const { ongoingWebinar, showOngoingWebinar, setShow } = useContext(OngoingWebinarContext)

  return (
    <Layout headTitle={headTitle} headDescription={headDescription}>
      {showOngoingWebinar && (
        <ModalLastWebinar ongoingWebinar={ongoingWebinar} handleClose={() => setShow(false)} />
      )}
      <Main showGoBack backgroundImage={headerBackground}>
        <Header title="WEBINARS" className={classes.header}>
          <NavFilter />
        </Header>
        <Container className={classes.container}>
          <AppliedFilters />

          {webinars.length > 0 ? (
            <InfiniteScroll
              pageStart={offset}
              loadMore={loadItems}
              hasMore={offset * limit < webinarsCount}
              threshold={10}
              style={{ display: 'flex', flexWrap: 'wrap', overflow: 'unset' }}
            >
              <Grid container spacing={4}>
                {webinars.map(webinar => {
                  return (
                    <Grid key={`${webinar.id}_${webinar.title}`} item lg={3} md={4} sm={6} xs={12}>
                      <WebinarCard webinar={webinar} size="adapt" />
                    </Grid>
                  )
                })}
              </Grid>
            </InfiniteScroll>
          ) : (
            <div>No se encontraron resultados</div>
          )}
        </Container>
      </Main>
    </Layout>
  )
}
