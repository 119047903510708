import React from 'react'
import { Container, Grid } from '@material-ui/core'

import { Header, Layout, Main } from '@components'
import background from '@assets/images/bg-privacy-policies.jpg'

import { useStyles } from '../styles'

export const PrivacyPolicies = () => {
  const classes = useStyles()

  return (
    <Layout>
      <Main showGoBack className={classes.main}>
        <img src={background} className={classes.bgHeader} alt="Imagen de cabecera" />
        <Container className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Header title="AVISO DE PRIVACIDAD" titleClass={classes.title} />
            </Grid>
            <Grid item xs={12} md={8}>
              <h2>Aplicación y Organizaciones contempladas en este Aviso</h2>
              <p>
                Takeda Argentina S.A. forma parte de un grupo de empresas con operaciones a nivel
                global. La organización de Takeda incluye a la compañía matriz, Takeda
                Pharmaceutical Company Limited, y sus entidades afiliadas. Para obtener la
                información de contacto de su afiliada local, por favor, consulte el sitio web de
                Takeda: www.takeda.com/es-ar.
              </p>

              <h2>Información que recopila Takeda y cómo la utilizamos</h2>

              <p>
                Este documento le informa acerca de la forma en que Takeda recopila y procesa sus
                datos personales a fin de obtener su consentimiento cuando corresponda o sea
                necesario, para procesar estos datos, conforme se describe en este Aviso de
                privacidad. Por ejemplo, en determinadas situaciones, si usted solicita información
                de Takeda o celebra un acuerdo con Takeda es posible que debamos procesar sus datos
                personales para poder interactuar con usted.
              </p>

              <h2>Podremos recopilar información como:</h2>

              <ul>
                <li>
                  Su información de contacto (nombre, dirección postal, dirección de correo
                  electrónico, número telefónico, etc.),
                </li>
                <li>
                  Experiencia profesional y calificaciones, actividades desarrolladas,
                  conocimientos,
                </li>
                <li>Preferencias de contacto, </li>
                <li>Intereses (por ejemplo, sitios web de medicina que consulta), </li>
                <li>
                  Método preferido de mantener actualizado su conocimiento científico, e información
                  sobre nuestras interacciones y reuniones.
                </li>
              </ul>

              <h2>Esta información le permitirá a Takeda: </h2>

              <ul>
                <li>
                  Enviarle cartas informativas, en particular por medios electrónicos, planificar
                  llamadas en función de sus intereses científicos, ofrecerle información sobre
                  nuestros productos y servicios,
                </li>
                <li>
                  Brindarle información y documentos científicos ya sea directamente o a través de
                  terceros,
                </li>
                <li>
                  Ofrecerle la posibilidad de asistir a congresos, reuniones y otras actividades
                  educativas y participar en investigaciones de mercado y análisis estadísticos,
                </li>
                <li>Organizar y analizar estadísticas e investigaciones de mercado, y </li>
                <li>
                  En general, nos permitirá cumplir con nuestras obligaciones legales (en particular
                  la transmisión de información cuando lo soliciten las autoridades).
                </li>
                <li>Lleva a cabo auditorías internas. </li>
                <li>Solicitar el desarrollo de presentaciones o servicios de consultoría. </li>
                <li>
                  Cumplir con las normas nacionales e internacionales de farmacovigilancia y de
                  quejas de productos.
                </li>
                <li>Programar la visita médica de los representantes médicos de Takeda. </li>
                <li>Entregar muestras médicas de Takeda. </li>
                <li>Realizar pagos a su cuenta bancaria por servicios prestados a Takeda. </li>
              </ul>

              <p>
                Usted no está obligado a brindarle a Takeda sus datos personales para los fines
                mencionados anteriormente. No obstante, en caso de que decida no hacerlo, podrá ser
                difícil o imposible para Takeda ofrecerle la información y los servicios mencionados
                anteriormente.
              </p>

              <p>
                Takeda también procesa datos personales mediante el registro, actualización,
                selección, elaboración y clasificación de la información recopilada por los
                representantes de ventas de Takeda y demás personal de Takeda (por ejemplo, Medical
                Science Liaisons, “MSL”). Este procesamiento de datos puede derivar en la
                clasificación de médicos en función de, por ejemplo:
              </p>

              <ul>
                <li>Experiencia, </li>
                <li>Interés científico en las áreas terapéuticas a las que se dedica Takeda, </li>
                <li>Productos de Takeda, </li>
                <li>
                  La cantidad de pacientes que trata o que están en tratamiento en el centro de
                  salud que corresponda.
                </li>
              </ul>

              <p>
                Estas actividades de determinación de perfil incluyen la interpretación de la
                información recopilada durante las llamadas entre usted, el médico, y nuestros
                representantes de ventas, o recopilada durante reuniones entre usted y otro personal
                de Takeda, así como mediante el análisis de datos disponibles en canales públicos
                (por ejemplo, disertaciones en congresos u otros eventos educativos).
                <br />
                También podremos utilizar su información para nuestros propios fines de
                investigación relacionados con nuestra actividad comercial, a fin de responder a
                solicitudes de acceso y para proteger los derechos y los intereses legales y
                comerciales de Takeda. Podremos combinar la información que usted brinde con otra
                información recopilada activa y pasivamente para los propósitos que se describen en
                este Aviso de privacidad.
              </p>

              <h2>Recopilación de información disponible públicamente/comercialmente</h2>

              <p>
                En algunos casos, podremos enriquecer nuestras bases de datos con información que
                recibamos de terceros y con información pública o comercialmente disponible y/o que
                se obtenga por cualquier otro medio legal.
              </p>

              <h2>Cómo compartimos la información personal</h2>

              <p>
                Nos comprometemos a no vender, rentar ni comercializar su información en condiciones
                distintas a las establecidas en este Aviso de privacidad. Al procesar sus datos
                personales, conforme se describe en este Aviso de privacidad, Takeda podrá compartir
                su información con fines que sean coherentes con los descritos en este Aviso de
                privacidad. Por ejemplo, podremos divulgar sus datos personales a nuestras afiliadas
                en todo el mundo que acepten tratar la información conforme a este Aviso de
                privacidad.
                <br />
                Además, podremos divulgar sus datos personales a terceros que se encuentren en
                países donde las leyes de protección de datos pueden no brindar el mismo nivel de
                protección que en su país de residencia, pero únicamente (i) a contratistas a los
                que recurrimos como soporte de nuestros negocios (por ejemplo, servicios de
                logística, soporte técnico), en cuyo caso requeriremos que dichos terceros se
                comprometan a tratar la información conforme a nuestras políticas; o (ii) en el caso
                de una venta, cesión o cualquier otra forma de transferencia de actividades en
                relación con estos datos, en cuyo caso requeriremos que el comprador se comprometa a
                procesar dicha información conforme a nuestro Aviso de privacidad; o (c) cuando lo
                exijan la ley, las órdenes de un tribunal u otras normas aplicables.
              </p>

              <h2>Cómo protegemos sus datos personales</h2>

              <p>
                Takeda toma las medidas comercialmente razonables para proteger su información de su
                pérdida, mal uso, acceso no autorizado, divulgación, alteración o destrucción.
              </p>

              <h2>Cuánto tiempo conservamos sus datos personales</h2>

              <p>
                Conservaremos sus datos personales durante todo el tiempo que sea estrictamente
                necesario para los fines mencionados en este Aviso de privacidad o durante el tiempo
                que sea necesario por ley y, en general, durante el período necesario para
                satisfacer nuestras necesidades legítimas.
              </p>

              <h2>Cómo acceder a sus datos personales</h2>

              <p>
                Usted podrá solicitar el acceso a sus datos personales y/o la rectificación de los
                mismos (por ejemplo, para asegurarse de que sigan siendo exactos, actuales y
                completos) en cualquier momento, comunicándose con nosotros como se indica más
                adelante (“Contacto”). Tomaremos las medidas que sean razonables para actualizar,
                corregir o eliminar los datos personales que estén en nuestro poder que usted nos
                haya enviado anteriormente o que hayamos adquirido de otras fuentes. Además, con
                argumentos legítimos, usted puede objetar el procesamiento de datos sobre su
                persona.
              </p>

              <h2>Redes sociales</h2>

              <p>
                Por lo general, las redes sociales son herramientas interactivas que le permiten
                colaborar y compartir información. Takeda podrá recopilar ciertos datos personales
                suyos para permitirle utilizar las funciones en línea de las redes sociales. Takeda
                también podrá utilizar estas herramientas para publicar o compartir datos personales
                con otros. Al utilizar redes sociales, deberá estar muy atento a los datos
                personales que decida compartir con otros. Takeda le brinda notificaciones y
                opciones sobre cómo se recopilan, utilizan y divulgan los datos personales en sus
                sitios web y redes sociales. Al participar en actividades en redes sociales, usted
                no debería publicar información sobre terceros sin su consentimiento.
              </p>

              <h2>
                Acceso no autorizado por parte de terceros, pérdida o robo de sus datos personales
              </h2>

              <p>
                En caso de que terceros no autorizados accedan a sus datos personales o en caso de
                pérdida o robo de los mismos, Takeda hará todo lo que sea comercialmente razonable
                para notificarle en la medida que lo establezca la ley y para informarle qué tipos
                de datos personales fueron afectados, mediante la información de contacto que usted
                nos haya brindado o por cualquier otro medio razonable.
              </p>

              <h2>Contacto/Responsabilidad</h2>

              <p>
                Si tiene preguntas o comentarios sobre este Aviso de privacidad o desea que su
                información se actualice o se elimine de nuestros archivos, por favor, comuníquese
                con nosotros en la siguiente dirección: aviso.privacidad@takeda.com. o a
                legalargentina@takeda.com
              </p>

              <h2>Su consentimiento</h2>

              <p>
                Además de las situaciones en las que su consentimiento es implícito, por ejemplo,
                cuando celebra un acuerdo con Takeda o presenta una solicitud a Takeda, Takeda desea
                obtener su consentimiento para recopilar y procesar sus datos personales como se
                describe en este Aviso de privacidad. Por lo tanto, le pedimos que confirme su
                consentimiento completando la casilla: “He leído y acepto la política de datos
                personales de Takeda” en el siguiente Link: www.espaciooncologia.com/sign-up
              </p>

              <p>
                Con su autorización, nos complacería contactarlo, incluso por medios electrónicos
                (por ejemplo, correo electrónico o SMS) para enviarle información sobre productos
                (incluidos productos medicinales comercializados por Takeda) y servicios que
                consideramos que podrían ser de su interés con base en su campo de especialización.
                En cualquier momento, usted tendrá el derecho de optar por no recibir estas
                comunicaciones electrónicas comunicándose con nosotros en la siguiente dirección:
                aviso.privacidad@takeda.com. o a legalargentina@takeda.com
              </p>

              <p>
                Acepto que me contacten por medios electrónicos (por ejemplo, correo electrónico)
                para recibir información como se describe en este Aviso de privacidad.
              </p>
            </Grid>
          </Grid>
        </Container>
      </Main>
    </Layout>
  )
}
