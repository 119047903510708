import React, { FC } from 'react'
import Carousel from 'react-material-ui-carousel'
import { useQuery } from 'react-query'

import { QUERY_KEYS } from '@api'

import { Item } from './components'
import { useStyles } from './styles'
import { HomeCarouselItemsApi } from '../../api'

const HomeCarousel: FC = () => {
  const classes = useStyles()

  const { data } = useQuery(
    [QUERY_KEYS.HOME_CAROUSEL_ITEMS],
    HomeCarouselItemsApi.getHomeCarouselItems,
    {
      refetchOnWindowFocus: false,
    },
  )

  return (
    <Carousel className={classes.carousel}>
      {data?.homeCarouselItems.map(item => (
        <Item key={item.id} carouselItem={item} />
      ))}
    </Carousel>
  )
}
export { HomeCarousel }
