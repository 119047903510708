import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    top: '50% !important',
    left: '50% !important',
    transform: 'translate(-50%, -50%) !important',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: '10px',
    right: '15px',
  },
  modalCard: {
    width: 500,
    position: 'relative',
  },
  modalContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  title: {
    padding: 0,
    marginBottom: 0,
    textAlign: 'center',
    fontSize: '1.2rem',
  },
  button: {
    borderRadius: 10,
    '&[disabled]': {
      opacity: 0.7,
      color: 'rgba(255,255,255, 0.5)',
    },
  },
  actions: {
    marginTop: 'auto',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}))

export { useStyles }
