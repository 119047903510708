import React, { FC, useEffect } from 'react'
import { Button, Container, TextField } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { FormAlert, Layout, Main } from '@components'
import logo from '@assets/images/logo-tfc.png'
import { useAccount } from '@hooks'

import { useStyles } from '../../styles'
import { ResetPasswordForm } from './types'
import { validationSchema } from './validations'

interface Props {
  token: string
}

const ResetPassword: FC<Props> = ({ token }) => {
  const classes = useStyles()

  // (Takeda ID) const [stateToken, setStateToken] = useState<string | undefined>('')

  const { resetPassword, successMessage, errorMessage, isSuccess } = useAccount()

  const { control, handleSubmit, errors, setValue } = useForm({
    defaultValues: { newPassword2: '' },
    resolver: yupResolver(validationSchema),
  })

  const onSubmit: SubmitHandler<ResetPasswordForm> = async ({ newPassword }) => {
    resetPassword({ stateToken: token, newPassword })
    // TODO - Para takeda ID se envia stateToken - resetPassword({ stateToken, newPassword })
  }

  // TODO - VerifyToken es necesario en la implementación de Takeda ID
  // useEffect(() => {
  //   verifyToken({ recoveryToken: token }).then((response: string | undefined) => {
  //     setStateToken(response)
  //   })
  // }, [token, verifyToken])

  useEffect(() => {
    if (isSuccess) {
      setValue('newPassword', '')
      setValue('newPassword2', '')
    }
  }, [isSuccess, setValue])

  return (
    <Layout hideHeader>
      <Main showGoBack={!!token} showGoHome={!token} className={classes.main}>
        <Container className={classes.container}>
          <NavLink to="/" className={classes.logo}>
            <img src={logo} alt="Espacio Oncología Logo" style={{ width: 420, maxWidth: '90%' }} />
          </NavLink>
          {token ? (
            <form onSubmit={handleSubmit(onSubmit)} className={classes.contentWrapper}>
              <p>Ingresá tu nueva contraseña.</p>

              <Controller
                name="newPassword"
                control={control}
                as={
                  <TextField
                    type="password"
                    label="Nueva contraseña"
                    placeholder="Ingresar tu nueva contraseña"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                  />
                }
              />
              {errors.newPassword && <FormAlert>{errors.newPassword.message}</FormAlert>}
              <Controller
                name="newPassword2"
                control={control}
                as={
                  <TextField
                    type="password"
                    label="Repetir contraseña"
                    placeholder="Volvé a ingresar tu contraseña"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                  />
                }
              />
              {errors.newPassword2 && <FormAlert>{errors.newPassword2.message}</FormAlert>}
              {(successMessage || errorMessage) && (
                <div className={`${classes.submitAlert}`}>
                  <FormAlert type={successMessage ? 'textSuccess' : 'textError'}>
                    {successMessage ?? errorMessage}
                  </FormAlert>
                </div>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={isSuccess}
              >
                Actualizar contraseña
              </Button>
            </form>
          ) : (
            token !== '' && (
              <div className={classes.contentWrapper}>
                El reestablecimiento de la contraseña expiró o se produjo un error inesperado.
                Vuelva a intentarlo en unos minutos.
              </div>
            )
          )}
        </Container>
      </Main>
    </Layout>
  )
}

export { ResetPassword }
