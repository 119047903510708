import { Speaker } from '@types'
import { api } from '@utils'

interface GetSpeakerResponse {
  speaker: Speaker
}

const getSpeaker = async (_: string, id: number) => {
  if (!id) return null
  const response = await api.get<GetSpeakerResponse>(`/speakers/${id}`)
  return response.data.speaker
}

export const SpeakersGlobalApi = { getSpeaker }
