import React, { useContext, useState } from 'react'
import { Button, ListItem, Menu, MenuItem } from '@material-ui/core'
import { ExpandMore, ExpandLess } from '@material-ui/icons'

import {AuthContext, LoginContext} from '@contexts'

import { useStyles } from '../../styles'
import {NavLink} from "react-router-dom";

const ActividadNavItems  = () => {
  const classes = useStyles()

  const [anchorElActividad, setAnchorEl] = useState<null | HTMLElement>(null)

  const { isAuthenticated } = useContext(AuthContext)
  const { setIsOpen } = useContext(LoginContext)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
      <div>
        <ListItem className={classes.navItem}>
          <Button
              aria-controls="simple-menu-main"
              aria-haspopup="true"
              className={`${classes.navLink} ${classes.menuNavItem}`}
              onClick={handleClick}
          >
            Actividades
            {anchorElActividad ? <ExpandLess /> : <ExpandMore />}
          </Button>
          <Menu
              id="simple-menu-main"
              anchorEl={anchorElActividad}
              keepMounted
              open={Boolean(anchorElActividad)}
              onClose={handleClose}
              style={{ transformOrigin: 'left bottom' }}

              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}

              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <MenuItem onClick={handleClose} component="div">
              <ListItem className={classes.navItem}>
                <NavLink to="/webinars" style={{ textDecoration: 'none' }} className='MuiButton-root MuiButton-label '>
                  Webinars
                </NavLink>
              </ListItem>
            </MenuItem>
            {isAuthenticated ? (
           <MenuItem onClick={handleClose}>
              <ListItem  className={classes.navItem}>
                <NavLink to="/podcasts" style={{ textDecoration: 'none' }} className='MuiButton-root MuiButton-label'>
                  Podcasts
                </NavLink>
              </ListItem>
            </MenuItem>
            ): <ListItem className={classes.navItem}>
                    <Button
                  onClick={() => setIsOpen(true)}
                  style={{ textDecoration: 'none', padding: '4px 34px' }} className='MuiButton-root MuiButton-label'>
                    Podcasts
              </Button>
            </ListItem> }
          </Menu>
        </ListItem>

      </div>
  )
}

export { ActividadNavItems }
