import * as yup from 'yup'

import { ERRORS } from '@constants'

export const validationSchema = yup.object().shape({
  newPassword: yup
    .string()
    .trim()
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z#?!@$%^&*\d]{8,}$/, ERRORS.FORMAT.PASSWORD)
    .required(ERRORS.REQUIRED.DEFAULT),
  newPassword2: yup.string().oneOf([yup.ref('newPassword')], ERRORS.FORMAT.PASSWORD_MATCH),
  // .required(ERRORS.FORMAT.PASSWORD),
})
