import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  bgHeader: {
    width: '100%',
    height: 200,
    objectFit: 'cover',
  },
  main: {
    fontSize: '1.06rem',
    paddingTop: 0,
    '& p, & ol': {
      marginBottom: 40,
    },
  },
  header: {
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  container: {
    paddingTop: 30,
  },
  title: {
    width: '100%',
    fontSize: '3rem',
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 0,
    // color: '#B0B0B0',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  submitAlert: {
    textAlign: 'center',
    margin: 20,
  },
}))

export { useStyles }
