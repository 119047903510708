import React, { FC, useContext } from 'react'
import { Container, Grid } from '@material-ui/core'
import { useQuery } from 'react-query'

import { QUERY_KEYS, TherapeuticAreasGlobalApi } from '@api'
import { Layout, Main, ModalLastWebinar } from '@components'

import { HomeCarousel, SpecialtyCard } from './components'
import { useStyles } from './styles'
import { OngoingWebinarContext } from '@contexts'

const headTitle = 'Espacio Oncología - Conexión + Integración / TAKEDA'
const headDescription =
  'Un espacio desarrollado por TAKEDA, para fomentar la conexión e integración sobre temas de actualización médica/científica en Oncología.'

const Home: FC = () => {
  const classes = useStyles()

  const { data: therapeuticAreas } = useQuery(
    [QUERY_KEYS.THERAPEUTIC_AREAS],
    TherapeuticAreasGlobalApi.getTherapeuticAreas,
  )
  const { ongoingWebinar, showOngoingWebinar, setShow } = useContext(OngoingWebinarContext)

  return (
    <Layout headTitle={headTitle} headDescription={headDescription}>
      {showOngoingWebinar && (
        <ModalLastWebinar ongoingWebinar={ongoingWebinar} handleClose={() => setShow(false)} />
      )}
      <Main className={classes.main}>
        <HomeCarousel />
        <Container className={classes.container}>
          <Grid container spacing={3} direction="row" alignItems="center" justify="center">
            {therapeuticAreas?.map(ta => (
              <Grid item sm={6} md={3} lg={3} key={ta.name}>
                <SpecialtyCard therapeuticArea={ta} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Main>
    </Layout>
  )
}

export { Home }
