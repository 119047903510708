import React, { FC } from 'react'
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { useHistory } from 'react-router'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Speaker, Webinar } from '@types'

import { useStyles } from './styles'
import { AccessToWebinarButton } from './components'

interface Props {
  className: string
  speakers: Speaker[]
  webinar: Webinar | null
}

export const Aside: FC<Props> = ({ className, speakers, webinar }) => {
  const classes = useStyles()
  const { push } = useHistory()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <aside className={className}>
      {speakers.length > 0 && (
        <>
          <Typography variant="h5" className={classes.title}>
            Speakers:
          </Typography>
          <ScrollContainer className={`scroll-container ${classes.scorller}`}>
            <List className={classes.speakerList}>
              {speakers.map(speaker => (
                <ListItem
                  alignItems="center"
                  onClick={() => push(`/speakers/${speaker.id}`)}
                  className={classes.speakerItem}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={speaker.name}
                      src={speaker.profilePictureUrl}
                      className={classes.speakerAvatar}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    style={{ marginLeft: '10px' }}
                    classes={{ primary: classes.speakerName }}
                    primary={`${speaker.lastName}, ${speaker.name}`}
                    secondary={
                      <Typography component="p" color="inherit">
                        {speaker.specialty} / {speaker.country}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </ScrollContainer>

          {webinar && !isMobile && <AccessToWebinarButton webinar={webinar} />}
        </>
      )}
    </aside>
  )
}
