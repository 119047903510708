import { useContext, useEffect, useState } from 'react'
import { useMutation } from 'react-query'

import { AuthContext, BackdropContext } from '@contexts'
import { ApiResponse, UserCache } from '@types'
import { QUERY_KEYS } from '@api'
import { setAuthHeader, removeAuthHeader } from '@utils'

import { useLocalStorage } from '../useLocalStorage'
import { FormMedicaApi } from './api'
// import { FormMedicaApi } from './apiTakeda'
import { LoginPayload, RegisterPayload } from './types'
import { useErrorHandler } from '../useErrorHandler'

const useFormMedica = () => {
  const [successMessage, setSuccessMessage] = useState('')

  const { setBackdropIsOpen } = useContext(BackdropContext)
  const { setIsAuthenticated, setUserLoggedIn } = useContext(AuthContext)
  const [sessionToken, setSessionToken, clearUserToken] = useLocalStorage<string>(
    QUERY_KEYS.SESSION_TOKEN,
  )
  const { errorMessage, setErrorMessage } = useErrorHandler()

  const [userLogin, { isSuccess: loginSuccess, isLoading: loginIsLoading }] = useMutation(
    FormMedicaApi.login,
    {
      onMutate: () => {
        setBackdropIsOpen(true)
      },
      onSuccess: ({ user, sessionToken: token }) => {
        if (user) {
          setUserLoggedIn(user)
          setSessionToken(token)
          setAuthHeader(token)
        }
        setSuccessMessage('Iniciando...')
        setIsAuthenticated(true)
        setBackdropIsOpen(false)
      },
      onError: ({ type, field }: ApiResponse) => {
        setErrorMessage(type, field)
        setBackdropIsOpen(false)
      },
    },
  )

  const [
    loginWithToken,
    { isSuccess: loginWithTokenSuccess, isLoading: loginWithTokenIsLoading },
  ] = useMutation(FormMedicaApi.loginWithToken, {
    onSuccess: async (user: UserCache) => {
      setUserLoggedIn(user)
      setIsAuthenticated(true)
    },
  })

  const [formRegister, { isSuccess: registerSuccess, isLoading: singupIsLoading }] = useMutation(
    FormMedicaApi.register,
    {
      onMutate: () => {
        setBackdropIsOpen(true)
      },
      onSuccess: () => {
        setBackdropIsOpen(false)
      },
      onError: ({ type, field }: ApiResponse) => {
        setErrorMessage(type, field)
      },
    },
  )

  const login = async (data: LoginPayload) => {
    await userLogin(data)
  }

  const register = (data: RegisterPayload) => {
    formRegister(data)
  }

  const logout = () => {
    setBackdropIsOpen(true)
    removeAuthHeader()
    clearUserToken()
    setTimeout(() => {
      window.location.href = '/'
    }, 1000)
  }

  useEffect(() => {
    if (!sessionToken) {
      setIsAuthenticated(false)
      setUserLoggedIn(undefined)
    }
  }, [sessionToken, setIsAuthenticated, setUserLoggedIn])

  useEffect(() => {
    setBackdropIsOpen(singupIsLoading || loginIsLoading || loginWithTokenIsLoading)
  }, [loginIsLoading, setBackdropIsOpen, singupIsLoading, loginWithTokenIsLoading])

  return {
    login,
    loginWithToken,
    register,
    logout,
    errorMessage,
    setErrorMessage,
    successMessage,
    isSuccess: loginSuccess || loginWithTokenSuccess || registerSuccess,
  }
}

export { useFormMedica }
