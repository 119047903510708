import React, { createContext, Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { GetHomeInfo } from '@api'

type ContextType = {
  ongoingWebinar: Object
  setOngoingWebinar: Dispatch<SetStateAction<Object>>
  showOngoingWebinar?: boolean
  setShow: Dispatch<SetStateAction<boolean>>
}

const OngoingWebinarContext = createContext<ContextType>({
  setOngoingWebinar: () => {},
  ongoingWebinar: {},
  setShow: () => {},
})

const OngoingWebinarProvider: FC = ({ children }) => {
  const [ongoingWebinar, setOngoingWebinar] = useState({})
  const [showOngoingWebinar, setShow] = useState(false)
  const { getOngoingWebinar } = GetHomeInfo

  useEffect(() => {
    getOngoingWebinar().then(response => {
      setOngoingWebinar(response)
      setShow(Object.keys(response).length > 0)
    })
  }, [getOngoingWebinar, setOngoingWebinar, setShow])

  return (
    <OngoingWebinarContext.Provider
      value={{
        ongoingWebinar,
        setOngoingWebinar,
        showOngoingWebinar,
        setShow,
      }}
    >
      {children}
    </OngoingWebinarContext.Provider>
  )
}

export { OngoingWebinarProvider, OngoingWebinarContext }
