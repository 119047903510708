import React, { Dispatch, FC, SetStateAction } from 'react'
import { Modal, Fade, Container, Card, CardContent, Button } from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { useGlobalStyles } from '@styles'

import { useStyles } from './styles'

interface Props {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  handleAcceptClick?: () => void
}

const ModalAlert: FC<Props> = ({ children, isOpen, setIsOpen, handleAcceptClick }) => {
  const globalClasses = useGlobalStyles()
  const classes = useStyles()

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} className={globalClasses.modal}>
      <Fade in={isOpen} timeout={500}>
        <Container className={globalClasses.modalContainer}>
          <Card className={`${globalClasses.modalCard} ${classes.modalCard}`}>
            <Close className={globalClasses.modalCloseIcon} onClick={() => setIsOpen(false)} />

            <CardContent>
              <div className={globalClasses.formGroup}>{children}</div>
              <div style={{ textAlign: 'center' }}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleAcceptClick}
                >
                  Aceptar
                </Button>
              </div>
            </CardContent>
          </Card>
        </Container>
      </Fade>
    </Modal>
  )
}

export { ModalAlert }
