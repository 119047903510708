import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: 30,
  },
  logo: {
    marginBottom: 40,
  },
  header: {
    width: '100%',
    marginBottom: 0,
  },
  contentWrapper: {
    width: '100%',
    maxWidth: 628,
    margin: '0 auto',
    padding: 26,
    borderRadius: 35,
    boxShadow: '5px 5px 50px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#FFF',
  },
  button: {
    width: 200,
    margin: '20px auto',
    display: 'block',
  },
})

export { useStyles }
