import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import { RecoveryToken, ResetPassword } from './components'

const RecoveryPassword = () => {
  const [recoveryToken, setRecoveryToken] = useState<string | null>()

  const params = new URLSearchParams(useLocation().search)

  useEffect(() => {
    setRecoveryToken(params.get('recoveryToken'))
  }, [params])

  return recoveryToken ? <ResetPassword token={recoveryToken} /> : <RecoveryToken />
}

export { RecoveryPassword }
