import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  box: {
    display: 'flex',
    flexFlow: 'wrap',
    width: '100%',
  },
  filterDrawerInner: {
    display: 'flex',
    flexDirection: 'column',
    padding: 30,
  },
  filterButton: {
    backgroundColor: '#B0B0B0',
    color: '#FFFFFF',
    position: 'fixed',
    top: 116,
    zIndex: 1,
    right: theme.spacing(3),
  },
}))

export { useStyles }
