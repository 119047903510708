import React, { Dispatch, FC, SetStateAction, useContext, useState } from 'react'
import { Button, ListItem, Menu, MenuItem } from '@material-ui/core'
import { ExpandMore, ExpandLess } from '@material-ui/icons'

import { AuthContext } from '@contexts'

import { useStyles } from '../../styles'

interface Props {
  handleUserProfileModalOpen: Dispatch<SetStateAction<boolean>>
  handleLogoutModalOpen: Dispatch<SetStateAction<boolean>>
}

const UserNavItems: FC<Props> = ({ handleLogoutModalOpen, handleUserProfileModalOpen }) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { userLoggedIn } = useContext(AuthContext)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <ListItem className={classes.navItem}>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          className={`${classes.navLink} ${classes.userNavItem}`}
          onClick={handleClick}
        >
          Hola {userLoggedIn?.title} {userLoggedIn?.lastName}{' '}
          {anchorEl ? <ExpandLess /> : <ExpandMore />}
        </Button>
      </ListItem>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ transformOrigin: 'left bottom' }}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={handleClose}>
          <>
            <Button
              onClick={() => {
                handleUserProfileModalOpen(true)
              }}
            >
              Ver Perfil
            </Button>
          </>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <>
            <Button
              onClick={() => {
                handleLogoutModalOpen(true)
              }}
            >
              Cerrar sesión
            </Button>
          </>
        </MenuItem>
      </Menu>
    </div>
  )
}

export { UserNavItems }
