const CARD = {
  HUGE: {
    WIDTH: 52,
    HEIGHT: 62,
  },
  BIG: {
    WIDTH: 31.2,
    HEIGHT: 62,
  },
  SMALL: {
    WIDTH: 18.2,
    HEIGHT: 29.3,
  },
}

const SCROLL_WRAPPER = {
  SPACING: {
    VERTICAL: 1.68,
    HORIZONTAL: 1.68,
  },
}

export { CARD, SCROLL_WRAPPER }
