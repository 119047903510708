import { makeStyles } from '@material-ui/core'
import { COLORS } from '@styles'

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 700,
    textTransform: 'uppercase',
    fontSize: 14,
    color: COLORS.PINK_SWAN,
  },
  speakerList: {
    marginBottom: 50,
    display: 'flex',
    flexDirection: 'column',
  },
  speakerItem: {
    width: 'auto',
    cursor: 'pointer',
  },
  speakerAvatar: {
    width: 85,
    height: 85,
  },
  speakerName: {
    fontWeight: 700,
  },
  btnRegistrarme: {
    height: 40,
    padding: '10px 20px',
    fontSize: '1rem',
    fontWeight: 700,
    color: COLORS.WHITE,
    borderRadius: 20,
  },
  btnViewWebinar: {
    backgroundColor: COLORS.GRAY_DARK,
    width: '100%',
    height: 74,
    fontSize: '1.5rem',
    fontWeight: 700,
    color: COLORS.WHITE,
    borderRadius: 20,
  },
  scorller: {
    overflowX: 'auto',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      background: 'transparent',
      height: 0,
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'green',
    },
  },
}))

export { useStyles }
