const LAYOUT = {
  SCREEN_WIDTH: {
    SCREEN_WIDTH: window.innerWidth,
  },
  NAV: {
    // Unit of measurement: viewport width/height
    HEIGHT: 12.2,
  },
  FOOTER: {
    // Unit of measurement: viewport width/height
    HEIGHT: 9.6,
  },
  CONTAINER: {
    // Unit of measurement: px
    XL: 1320,
    LG: 1140,
    MD: 960,
    SM: 720,
    XS: 540,
    PADDING: 24,
  },
}

export { LAYOUT }
