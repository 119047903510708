import { api } from '@utils'

interface TherapeuticArea {
  id: number
  name: string
  color: string
  isActive: boolean
}

interface GetTherapeuticAreasResponse {
  therapeuticAreas: TherapeuticArea[]
}

const getTherapeuticAreas = async () => {
  const response = await api.get<GetTherapeuticAreasResponse>('/therapeutic-areas')

  return response.data.therapeuticAreas
}

type Payload = {
  id: number
}

type getTherapeuticAreaResponse = {
  therapeuticArea: {
    id: number
    name: string
    isActive: boolean
    color: string
  }
}

const getTherapeuticArea = async (_: string, { id }: Payload) => {
  if (id) {
    const response = await api.get<getTherapeuticAreaResponse>(`/therapeutic-areas/${id}`)

    return response.data.therapeuticArea
  }
  return null
}

export const TherapeuticAreasGlobalApi = { getTherapeuticAreas, getTherapeuticArea }
