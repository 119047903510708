import { makeStyles } from '@material-ui/core'

import { CARD } from '../../constants'

const useStyles = makeStyles({
  webinar: {
    position: 'relative',
    backgroundSize: 'cover',
    overflow: 'hidden',
    boxShadow: '0 0 20px 0 rgba(0,0,0,0.4)',
    borderRadius: 30,
    '&.huge': {
      width: `${CARD.HUGE.WIDTH}vw`,
      height: `${CARD.HUGE.HEIGHT}vh`,
    },
    '&.adapt': {
      width: ' 100%',
      height: '62vh',
      maxHeight: 338,
    },
    '&.big': {
      width: `${CARD.BIG.WIDTH}vw`,
      height: `${CARD.BIG.HEIGHT}vh`,
    },
    '&.small': {
      width: `${CARD.SMALL.WIDTH}vw`,
      height: `${CARD.SMALL.HEIGHT}vh`,
    },
  },
  image: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    opacity: '0.5',
    borderRadius: 30,
  },
  webinarMedia: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0 22px',
    '.small &, .adapt &': {
      padding: '35px 25px',
    },
    '.adapt &': {
      alignItems: 'flex-start',
    },
  },
  body: {
    width: '100%',
  },
  title: {
    fontSize: '3.1vh',
    textAlign: 'left',
    fontWeight: 500,
    marginTop: 0,
    display: '-webkit-box',
    marginBottom: 10,
    // lineClamp: 2,
    // '.big &, .huge &': {
    //   lineClamp: 4,
    // },
    boxOrient: 'vertical',
    overflow: 'hidden',
    '.adapt &': {
      fontSize: 15,
    },
    '.small &': {
      marginBottom: 8,
      lineClamp: 2,
    },
  },
  date: {
    fontSize: '3.1vh',
    fontWeight: 700,
    marginTop: 10,
    '.adapt &': {
      fontSize: 20,
    },
  },
  day: {
    marginRight: 10,
  },
  startTime: {
    display: 'inline-block',
    border: '1px solid',
    borderRadius: '0 20px 20px 20px',
    padding: '2px 10px',
    fontSize: '.7rem',
    fontWeight: 700,
    lineHeight: '14px',
    marginTop: 11,
  },
  profileImage: {
    objectFit: 'cover',
    width: 40,
    height: 40,
  },
  line: {
    width: 146,
    margin: 0,
    borderTop: 'solid 1px rgba(0,0,0,0.15)',
    borderBottom: 'solid 1px rgba(255,255,255,0.3)',
  },
  speakers: {
    marginBottom: 10,
  },
  speaker: {
    fontSize: '2vh',
    marginTop: 20,
    '.adapt &': {
      fontSize: 13,
      marginTop: 10,
    },
    '.small &': {
      marginTop: 8,
    },
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  signUp: {
    position: 'absolute',
    bottom: 20,
    left: 20,
    fontSize: '1.24vh',
    width: 'auto',
    height: 'auto',
    padding: '8px 28px',
    fontWeight: 700,
    color: '#FFF',
    borderRadius: 13,
    '.small &': {
      display: 'none',
    },
  },
  arrowIcon: {
    marginLeft: 10,
  },
})

export { useStyles }
