import React, { FC } from 'react'
import { Button, Card, CardMedia } from '@material-ui/core'
import { useHistory } from 'react-router'
import moment from 'moment'

import ta1 from '@assets/images/therapeutic-areas/linfoma.jpg'
import ta2 from '@assets/images/therapeutic-areas/mieloma.jpg'
import ta3 from '@assets/images/therapeutic-areas/cancer.jpg'
import ta4 from '@assets/images/therapeutic-areas/otras-areas.jpg'
import tam1 from '@assets/images/therapeutic-areas/linfoma-mediana.jpg'
import tam2 from '@assets/images/therapeutic-areas/mieloma-mediana.jpg'
import tam3 from '@assets/images/therapeutic-areas/cancer-mediana.jpg'
import tam4 from '@assets/images/therapeutic-areas/otras-areas-mediana.jpg'
import tag1 from '@assets/images/therapeutic-areas/linfoma-grande.jpg'
import tag2 from '@assets/images/therapeutic-areas/mieloma-grande.jpg'
import tag3 from '@assets/images/therapeutic-areas/cancer-grande.jpg'
import tag4 from '@assets/images/therapeutic-areas/otras-areas-grande.jpg'
import Arrow from '@assets/images/shape.png'
import { Webinar, WebinarStatus } from '@types'
import { SHORT_DATE, HOUR_AND_MINUTES, SHORT_MONTH_AND_YEAR } from '@constants'

import { useStyles } from './styles'

interface Props {
  webinar: Webinar
  size: 'huge' | 'big' | 'small' | 'adapt'
  position?: number
}
export const WebinarCard: FC<Props> = ({ webinar, size }) => {
  const classes = useStyles()
  const history = useHistory()

  const renderImage = (taId: number) => {
    switch (taId) {
      case 1:
        if (size === 'huge' || size === 'small') return tag1
        if (size === 'big') return tam1
        return ta1
      case 2:
        if (size === 'huge' || size === 'small') return tag2
        if (size === 'big') return tam2
        return ta2
      case 3:
        if (size === 'huge' || size === 'small') return tag3
        if (size === 'big') return tam3
        return ta3
      default:
        if (size === 'huge' || size === 'small') return tag4
        if (size === 'big') return tam4
        return ta4
    }
  }

  const getLineClamp = (speakersQty: number) => {
    if (size === 'small') return 2
    switch (speakersQty) {
      case 1:
        return 7
      case 2:
        return 6
      case 3:
        return 4
      default:
        return 2
    }
  }

  return (
    <Card className={`${classes.webinar} webinar ${size}`}>
      <div
        className={classes.image}
        style={{
          filter:
            moment().utcOffset(-3).subtract(1, 'd').isAfter(webinar.date) ||
            webinar.status === WebinarStatus.finalized
              ? 'grayscale(100%)'
              : 'inherit',
        }}
      >
        <img
          src={renderImage(webinar.therapeuticArea.id)}
          alt={webinar.therapeuticArea.name}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </div>
      <CardMedia
        className={classes.webinarMedia}
        onClick={() => history.push(`/webinars/${webinar.id}`)}
      >
        <div className={classes.body}>
          <h3
            className={classes.title}
            style={{
              color:
                moment().utcOffset(-3).subtract(1, 'd').isBefore(webinar.date) &&
                webinar.status !== WebinarStatus.finalized
                  ? ''
                  : `${webinar.therapeuticArea.color}`,
              overflow: 'hidden',
              boxOrient: 'vertical',
              WebkitLineClamp: getLineClamp(webinar.speakers.length),
              lineClamp: getLineClamp(webinar.speakers.length),
            }}
          >
            {webinar.title}
          </h3>
          <hr
            className={classes.line}
            style={{
              color:
                moment().utcOffset(-3).subtract(1, 'd').isBefore(webinar.date) &&
                webinar.status !== WebinarStatus.finalized
                  ? ''
                  : `${webinar.therapeuticArea.color}`,
            }}
          />
          <div className={classes.speakers}>
            {webinar.speakers.slice(0, size === 'small' ? 1 : 4).map(speaker => {
              return (
                <div className={classes.speaker} key={speaker.id}>
                  {speaker.lastName}, {speaker.name}
                  {size === 'small' &&
                    webinar.speakers.length > 1 &&
                    ` + ${webinar.speakers.length - 1}`}
                </div>
              )
            })}
          </div>

          <div className={classes.date}>
            {moment().utcOffset(-3).isBefore(webinar.date) && (
              <span className={classes.day}>{moment(webinar.date).format(SHORT_DATE)}</span>
            )}

            <span
              className={classes.startTime}
              style={{
                color:
                  moment().utcOffset(-3).subtract(1, 'd').isBefore(webinar.date) &&
                  webinar.status !== WebinarStatus.finalized
                    ? ''
                    : `${webinar.therapeuticArea.color}`,
              }}
            >
              {moment().utcOffset(-3).isBefore(webinar.date)
                ? moment(webinar.date).format(HOUR_AND_MINUTES).replace(':00', '')
                : moment(webinar.date).format(SHORT_MONTH_AND_YEAR)}
            </span>
          </div>
        </div>

        {moment().utcOffset(-3).isBefore(webinar.date) &&
          webinar.status !== WebinarStatus.finalized && (
            <Button
              className={classes.signUp}
              style={{
                backgroundColor: `${webinar.therapeuticArea.color}`,
              }}
            >
              ACCEDÉ <img src={Arrow} alt="arrow" className={classes.arrowIcon} />
            </Button>
          )}
      </CardMedia>
    </Card>
  )
}
