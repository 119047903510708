import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  button: {
    fontSize: '1rem',
    color: '#FFF',
    padding: '5px 44px',
    borderRadius: 10,
    '&[disabled]': {
      opacity: 0.7,
      color: 'rgba(255,255,255, 0.5)',
    },
  },
  noButton: {
    backgroundColor: 'transparent',
    color: '#333',
  },
  buttonIcon: {
    marginLeft: 10,
  },
  modalCard: {
    width: 500,
    position: 'relative',
  },
})

export { useStyles }
