import { api } from '@utils'

import { GetHomeCarouselItemsResponse } from './types'

const getHomeCarouselItems = async () => {
  const params = {
    isActive: true,
  }
  const response = await api.get<GetHomeCarouselItemsResponse>('/home-carousel-items', { params })

  return response.data
}

export const HomeCarouselItemsApi = { getHomeCarouselItems }
