import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  header: {
    marginBottom: 0,
  },
  container: {
    paddingLeft: 60,
    paddingRight: 60,
  },
  titulo_podscats: {
    color:'#e24f49',
    fontSize: 20,
    marginBlockStart: 1,
    marginBlockEnd: 1,
    marginInlineStart: 0,
    marginInlineEnd: 0,
  },
  descripcion_podscats:{
    fontSize: 16,
  }
})

export { useStyles }
