import React, { FC } from 'react'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'

import { TherapeuticArea } from '@types'
import ta1 from '@assets/images/therapeutic-areas/linfoma-home.jpg'
import ta2 from '@assets/images/therapeutic-areas/mieloma-home.jpg'
import ta3 from '@assets/images/therapeutic-areas/cancer-home.jpg'
import ta4 from '@assets/images/therapeutic-areas/otras-areas-home.jpg'

import { useStyles } from './styles'

interface Props {
  therapeuticArea: TherapeuticArea
}

const SpecialtyCard: FC<Props> = ({ therapeuticArea }) => {
  const classes = useStyles()

  const renderImage = (taId: number) => {
    switch (taId) {
      case 1:
        return ta1
      case 2:
        return ta2
      case 3:
        return ta3
      case 4:
        return ta4
      default:
        return ta4
    }
  }

  return (
    <>
      <Card
        className={classes.item}
        style={{
          backgroundImage: `url(${renderImage(therapeuticArea.id)})`,
        }}
        onClick={
          () => {
            window.location.href = `/webinars/?therapeutic-area=${therapeuticArea.id}`
          }
          // push({
          //   pathname: `/webinars/therapeutic-areas/${therapeuticArea.id}`,
          // })
        }
      >
        <Typography className={classes.title} variant="h6">
          {therapeuticArea.name}
        </Typography>
      </Card>
    </>
  )
}

export { SpecialtyCard }
