import { makeStyles } from '@material-ui/core'

import { COLORS } from '@styles'

const useStyle = makeStyles(theme => ({
  navLink: {
    fontSize: 14,
    fontWeight: 700,
    margin: '0 15px',
    padding: '5px 0',
    textDecoration: 'none',
    color: COLORS.GRAY_HEADER,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10,
      padding: 0,
      margin: '10px 0',
    },
    '&.active': {
      [theme.breakpoints.up('md')]: {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
      [theme.breakpoints.down('sm')]: {
        borderLeft: `3px solid ${theme.palette.primary.main}`,
      },
    },
  },
}))

export { useStyle }
