type Errors = {
  [key: string]: {
    [key: string]: string
  }
}

export const ERRORS: Errors = {
  FORMAT: {
    EMAIL: 'Formato de correo electrónico incorrecto.',
    PASSWORD:
      'La contraseña no es correcta. Solo permite mayúscula/s, minúscula/s, número/s y al menos 8 caracteres.',
    PASSWORD_MATCH: 'La contraseña debe coincidir',
  },
  REQUIRED: {
    DEFAULT: 'Este campo es obligatorio.',
  },
  DUPLICATE_ENTRY_ERROR: {
    NAME: 'El dato ya existe en la base de datos.',
    EMAIL: 'Ya existe una cuenta con este correo electrónico.',
  },
  UNAUTHORIZED: {
    PASSWORD: 'Hubo un problema, revise los datos ingresados y/o asegúrese de validar su cuenta desde el e-mail que le enviamos al registrarse (no se olvide de revisar carpeta de Correo no deseado/SPAM)',
    ACCOUNTVERIFIED: 'La cuenta aún no ha sido verificada. Revise su correo electrónico.',
    TOMODERATE: 'El usuario se encuentra pendiente de aceptación',
    REJECTED: 'El usuario no ha sido aceptado. Por favor, póngase en contacto con el Administrador',
    DEFAULT: 'No tiene autorización para ralizar esta operación, verifique los datos.',
  },
  FORBIDDEN: {
    NAME: 'El correo electrónico no existe en nuestra base de datos.',
    DEFAULT: 'El dato ingresado no esta permitido o no existe en nuestra base de datos.',
  },
  GENERIC_ERROR: {
    DEFAULT: 'Se produjo un error. Vuelva a intentarlo mas tarde.',
    EMAIL:
      'El correo electrónico ingresado no pertenece a ningún usuario registrado. Por favor, vuelva a intentarlo o regístrese.',
  },
  RESOURCE_FIELD_IN_USE: {
    ENROLLMENT: 'Ya existe un usuario con esta matrícula.',
    EMAIL: 'Ya existe un usuario con este correo electrónico.',
  },
  RESOURCE_NOT_FOUND: {
    DEFAULT: 'Los datos ingresados son incorrectos',
    EMAIL:
      'El correo electrónico ingresado no pertenece a ningún usuario registrado. Por favor, vuelva a intentarlo o regístrese.',
  },
}
