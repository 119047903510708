import React, { useContext, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Button, Container, TextField, Typography } from '@material-ui/core'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from 'react-query'

import { FormAlert, Layout, Main } from '@components'
import { AuthContext, BackdropContext, LoginContext } from '@contexts'
import { useAuth } from '@hooks'
import { useGlobalStyles } from '@styles'

import { useStyles } from './styles'
import { LoginApi } from './api'
import { LoginFormType } from './types'
import { validationSchema } from './validations'

const defaultValues = {
  email: '',
  password: '',
}

export const Login = () => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const { push } = useHistory()
  const { setIsOpen } = useContext(LoginContext)

  const [queryParams] = useState(new URLSearchParams(useLocation().search))
  const [stateToken, setStateToken] = useState<string | undefined>('')

  const { isAuthenticated } = useContext(AuthContext)
  const { setBackdropIsOpen } = useContext(BackdropContext)

  const { login, errorMessage, successMessage, setErrorMessage } = useAuth()

  const { control, handleSubmit, errors } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const [verifyToken] = useMutation(LoginApi.verifyUser, {
    onMutate: () => setBackdropIsOpen(true),
    onSuccess: () => {
      setBackdropIsOpen(false)
      // TODO
    },
    onError: () => {
      setBackdropIsOpen(false)
      // TODO
    },
  })

  const onSubmit: SubmitHandler<LoginFormType> = data => {
    login(data)
  }

  useEffect(() => {
    const recoveryToken = queryParams.get('recoveryToken')
    if (stateToken === '' && recoveryToken) {
      verifyToken({ recoveryToken }).then((response: string | undefined) => {
        setStateToken(response ?? 'nonTakeda')
      })
    }
  }, [queryParams, stateToken, verifyToken])

  useEffect(() => {
    if (isAuthenticated) push('/')
  }, [isAuthenticated, push])


  return (
    <Layout>
      <Main>
        <Container className={classes.container}>
          {stateToken ? (
            <div className={classes.contentWrapper}>
              <div className={`${globalClasses.textCenter}`} style={{ marginBottom: 40 }}>
                <Typography component="h3" variant="h3">
                  <strong>Hola,</strong>
                </Typography>
                <p>Gracias por completar la registración.</p>
                <p>
                  Te damos la bienvenida a <strong>ESPACIO ONCOLOGIA&#174;.</strong>
                </p>
                {/* <p>Debes aguardar la activación, a realizarse en un máximo de 72hs.</p> */}
                <p>
                  Para comenzar a descubrir el contenido iniciá tu sesión haciendo clic <a className={classes.link} href='\login' rel="noopener noreferrer">aquí</a>
                </p>
              </div>
              <div>
                <Typography component="h4" variant="h4" color="primary">
                  ¿Qué puedo hacer con mi cuenta en ESPACIO ONCOLOGIA&#174;?
                </Typography>
                <Typography component="ul" color="primary">
                  <Typography component="li">Participar en actividades en vivo.</Typography>
                  <Typography component="li">Encontrar actividades on demand.</Typography>
                  <Typography component="li">
                    Descubrir <strong>contenidos de actualidad</strong> en oncología.
                  </Typography>
                </Typography>
              </div>
            </div>
          ) : (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={classes.contentWrapper}
              onChange={() => setErrorMessage('')}
            >
              <Controller
                name="email"
                control={control}
                as={
                  <TextField
                    label="Correo electrónico"
                    placeholder="Ingresar correo electrónico"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                  />
                }
              />
              {errors.email && <FormAlert>{errors.email.message}</FormAlert>}
              <Controller
                name="password"
                control={control}
                as={
                  <TextField
                    type="password"
                    label="Contraseña"
                    placeholder="Ingresar Contraseña"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                  />
                }
              />
              {errors.password && <FormAlert>{errors.password.message}</FormAlert>}
              {successMessage ||
                (errorMessage && (
                  <div
                    className={`${globalClasses.textCenter} ${globalClasses.mediumTopSeparator}`}
                  >
                    <FormAlert type={successMessage ? 'textSuccess' : 'textError'}>
                      {successMessage || errorMessage}
                    </FormAlert>
                  </div>
                ))}
              <Button type="submit" variant="contained" color="primary" className={classes.button}>
                Iniciar Sesión
              </Button>
              <Link to="/recovery-password">¿Olvidaste tu contraseña?</Link> -{' '}
              <Link to="/sign-up">¿Aún no tenés cuenta?</Link>
            </form>
          )}
        </Container>
      </Main>
    </Layout>
  )
}
