import React, { FC } from 'react'
import {
  Modal,
  Fade,
  Container,
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import EmailIcon from '@material-ui/icons/Email'
import PublicIcon from '@material-ui/icons/Public'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'

import { Header } from '@components'
import { useGlobalStyles } from '@styles'
import { UserCache } from '@types'
import { EMAIL_CONTACT } from '@constants'

import { useStyles } from './styles'

interface Props {
  isOpen: boolean
  userSelected: UserCache
  handleClose: () => void
}

export const UserProfileModal: FC<Props> = ({ isOpen, userSelected, handleClose }) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  return (
    <>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={globalClasses.modal}
      >
        <Fade in={isOpen} timeout={500}>
          <Container className={globalClasses.modalContainer}>
            <Card className={globalClasses.modalCard}>
              <CloseIcon className={globalClasses.modalCloseIcon} onClick={handleClose} />
              {userSelected && (
                <>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Header
                          title={`${userSelected.title} ${userSelected.name} ${userSelected.lastName}`}
                          className={classes.header}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <List component="ul" className={classes.list} aria-label="contacts">
                          <ListItem className={classes.listItem}>
                            <ListItemIcon>
                              <EmailIcon style={{ color: '#FC1622' }} />{' '}
                            </ListItemIcon>
                            <ListItemText primary={userSelected.email} />
                          </ListItem>
                          <ListItem className={classes.listItem}>
                            <ListItemIcon>
                              <AssignmentIndIcon style={{ color: '#FC1622' }} />{' '}
                            </ListItemIcon>
                            <ListItemText inset primary={userSelected.specialty} />
                          </ListItem>
                          <ListItem className={classes.listItem}>
                            <ListItemIcon>
                              <PublicIcon style={{ color: '#FC1622' }} />{' '}
                            </ListItemIcon>
                            <ListItemText inset primary={userSelected.country} />
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <Typography variant="body1">
                      <strong>
                        Si la información de tu perfil es incorrecta, por favor escribí a la
                        siguiente dirección{' '}
                        <Typography component="a" color="primary" href={`mailto: ${EMAIL_CONTACT}`}>
                          {EMAIL_CONTACT}
                        </Typography>
                      </strong>
                    </Typography>
                  </CardContent>
                </>
              )}
            </Card>
          </Container>
        </Fade>
      </Modal>
    </>
  )
}
