import { makeStyles } from '@material-ui/core'

import { LAYOUT } from '@constants'

const { NAV, FOOTER } = LAYOUT

const useStyles = makeStyles(theme => ({
  main: {
    position: 'relative',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    marginTop: `${NAV.HEIGHT}vh`,
    minHeight: `calc(100vh - ${NAV.HEIGHT}vh - ${FOOTER.HEIGHT}vh)`,
    paddingBottom: 28,
    [theme.breakpoints.down('md')]: {
      paddingTop: 28,
    },
    '&::before': {
      content: '""',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom right',
      backgroundSize: '90%',
      height: '100vh',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: -1,
    },
  },
  btnBack: {
    display: 'flex',
    margin: '50px auto',
  },
}))

export { useStyles }
