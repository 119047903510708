import { makeStyles, Theme } from '@material-ui/core/styles'
import { COLORS } from '@styles'

export interface StyleProps {
  urlString: string
}

export const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  item: {
    backgroundSize: 'cover',
    backgroundImage: ({ urlString }) => `url(${urlString})`,
    display: 'flex',
    alignItems: 'center',
    height: '48vh',
    width: '100%',
  },
  itemWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  button: {
    marginLeft: '10px',
    width: '10%',
  },
  title: {
    color: '#242424',
    fontSize: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
    fontWeight: 700,
    margin: 0,
  },
  description: {
    color: '#242424',
    fontSize: '24px',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: COLORS.GRAY,
    fontSize: '12px',
    textTransform: 'uppercase',
    fontStretch: 'normal',
    fontWeight: 'bold',
    background: '#e2e2e2',
    borderRadius: '6px',
    padding: '12px 32px',
    marginLeft: '20px',
  },
  container: {
    marginTop: 70,
    padding: 20,
    marginLeft: 150,
    maxWidth: 1500,
  },
  carousel: {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    marginTop: -20,
    height: '40vh',
  },
}))
