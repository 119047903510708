import { api } from '@utils'
import { VerifyTokenResponse } from '@types'

const verifyUser = async (payload: { recoveryToken: string | null }) => {
  try {
    const response = await api.post<VerifyTokenResponse>('/auth/login/token', {
      user: payload,
    })
    return response.data.user.stateToken
  } catch (err) {
    throw err.response.data.error
  }
}

export const LoginApi = { verifyUser }
