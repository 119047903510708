import React, { createContext, Dispatch, FC, SetStateAction } from 'react'
import { Backdrop, CircularProgress } from '@material-ui/core'

type ContextType = {
  backdropIsOpen?: boolean
  setBackdropIsOpen: Dispatch<SetStateAction<boolean>>
}

const BackdropContext = createContext<ContextType>({ setBackdropIsOpen: () => false })

const BackdropProvider: FC = ({ children }) => {
  const [backdropIsOpen, setBackdropIsOpen] = React.useState(false)

  return (
    <BackdropContext.Provider value={{ backdropIsOpen, setBackdropIsOpen }}>
      <Backdrop
        open={backdropIsOpen}
        onClick={() => setBackdropIsOpen(false)}
        style={{ zIndex: 9999, color: '#FFF' }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </BackdropContext.Provider>
  )
}

export { BackdropProvider, BackdropContext }
