import React from 'react'
import { Button, Container, TextField, Typography } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { FormAlert, Layout, Main } from '@components'
import logo from '@assets/images/logo-tfc.png'
import { useAccount } from '@hooks'
import { useGlobalStyles } from '@styles'

import { validationSchema } from './validations'
import { useStyles } from '../../styles'

const RecoveryToken = () => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  const { recoveryPassword, errorMessage, isSuccess } = useAccount()

  const defaultValues = {
    email: '',
  }

  const { control, handleSubmit, errors } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const onSubmit: SubmitHandler<{ email: string }> = data => {
    recoveryPassword(data)
  }

  return (
    <Layout hideHeader>
      <Main showGoBack={!isSuccess} showGoHome={!!isSuccess} className={classes.main}>
        <Container className={classes.container}>
          <NavLink to="/" className={classes.logo}>
            <img src={logo} alt="Espacio Oncología Logo" style={{ width: 420, maxWidth: '90%' }} />
          </NavLink>
          {!isSuccess ? (
            <form onSubmit={handleSubmit(onSubmit)} className={classes.contentWrapper}>
              <h2>¿Olvidaste tu contraseña?</h2>
              <p>
                Confirmanos tu correo electrónico para poder enviarte las instrucciones de cómo
                recuperarla.
              </p>
              <Controller
                name="email"
                control={control}
                as={
                  <TextField
                    label="Correo electrónico"
                    placeholder="Ingresar correo electrónico"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                  />
                }
              />
              {errors.email && <FormAlert>{errors.email.message}</FormAlert>}
              <Button type="submit" variant="contained" color="primary" className={classes.button}>
                Recuperar contraseña
              </Button>
              {errorMessage && (
                <div
                  className={`${globalClasses.textCenter} ${globalClasses.mediumBottomSeparator}`}
                >
                  <FormAlert>{errorMessage}</FormAlert>
                </div>
              )}
            </form>
          ) : (
            <div className={classes.contentWrapper}>
              <div className={`${globalClasses.textCenter}`} style={{ marginBottom: 40 }}>
                <Typography component="h3" variant="h3">
                  ¡Email enviado!
                </Typography>
                <Typography component="p">
                  Te hemos enviado un mail para que puedas restablecer tu contraseña.
                </Typography>
              </div>
            </div>
          )}
        </Container>
      </Main>
    </Layout>
  )
}

export { RecoveryToken }
