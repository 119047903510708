import React, { FC } from 'react'
import { Card, Avatar, Typography } from '@material-ui/core'
import { useHistory } from 'react-router'

import { useStyles } from './styles'
import { Speaker } from '../../types'

interface Props {
  speaker: Speaker
}

export const SpeakerCard: FC<Props> = ({ speaker }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Card className={classes.speaker} onClick={() => history.push(`/speakers/${speaker.id}`)}>
      <div>
        <Avatar src={speaker.profilePictureUrl} className={classes.profileImage} />
      </div>
      <div className={classes.body}>
        <Typography variant="h3" className={classes.name}>
          {speaker.lastName}, {speaker.name}
        </Typography>
        <Typography className={classes.subTitle}>
          <span>{speaker.specialty}</span> / <span>{speaker.country}</span>
        </Typography>
      </div>
    </Card>
  )
}
