import { makeStyles } from '@material-ui/core'

import { COLORS } from '@styles'

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: COLORS.GRAY_LIGHT,
    color: COLORS.GRAY,
    marginTop: 'auto',
    '& a': {
      color: 'inherit',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '.7rem',
  },
  left: {
    flexGrow: 1,
  },
  leftTop: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 0,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  nav: {
    '& > ul': {
      display: 'flex',
      listStyle: 'none',
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
        lineHeight: 2,
      },
      '.mobile &': {
        flexDirection: 'column',
        lineHeight: 3,
      },
      '& > li': {
        fontSize: '.7rem',
        [theme.breakpoints.up('md')]: {
          '&:nth-of-type(n + 2)': {
            borderLeft: '1px solid',
            marginLeft: 20,
            paddingLeft: 20,
          },
        },
        '.mobile &:nth-of-type(n + 2)': {
          border: 0,
        },
        '& > a': {
          color: 'inherit',
          // color: '#0000EE',
          textDecoration: 'none',
          [theme.breakpoints.down('sm')]: {
            textDecoration: 'underline',
          },
        },
      },
    },
  },
  logo: {
    height: '5.8vh',
    minHeight: 42,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  formMedica: {
    position: 'fixed',
    right: 10,
    bottom: '10%',
    zIndex: 10,
    '& img': {
      maxHeight: '10vh',
    },
  },
  formMedicaMobile: {
    position: 'fixed',
    right: 10,
    bottom: '1%',
    zIndex: 10,
  },
}))

export { useStyles }
