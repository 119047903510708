import React, { FC, useState } from 'react'
import { Box, Hidden, Fab, Drawer, Button } from '@material-ui/core'
import { Tune } from '@material-ui/icons'

import { Search, CategoriesFilter } from './components'
import { useStyles } from './styles'

export const NavFilter: FC = () => {
  const classes = useStyles()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  return (
    <>
      <Hidden smDown>
        <Box className={classes.box}>
          <CategoriesFilter />
          <Search />
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Search />
        <Fab className={classes.filterButton} onClick={() => setIsDrawerOpen(true)}>
          <Tune />
        </Fab>
        <Drawer anchor="bottom" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
          <div className={classes.filterDrawerInner}>
            <CategoriesFilter />
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsDrawerOpen(false)}
              style={{ marginTop: 20 }}
            >
              Cerrar
            </Button>
          </div>
        </Drawer>
      </Hidden>
    </>
  )
}
