import React, { FC } from 'react'
import { useHistory } from 'react-router'
import { useQuery } from 'react-query'
import { Button, Tooltip } from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { QUERY_KEYS, SpeakersGlobalApi, TherapeuticAreasGlobalApi } from '@api'

const AppliedFilters: FC = () => {
  const params = new URLSearchParams(window.location.search)
  const { push } = useHistory()

  const { data: speaker } = useQuery(
    [QUERY_KEYS.SPEAKERS, params.get('speakerID')],
    SpeakersGlobalApi.getSpeaker,
  )

  const { data: therapeuticArea } = useQuery(
    [QUERY_KEYS.THERAPEUTIC_AREAS, { id: params.get('therapeutic-area') }],
    TherapeuticAreasGlobalApi.getTherapeuticArea,
  )

  const removeUserFromFilter = (param: string) => {
    params.delete(param)
    push(`/webinars?${params.toString() ?? ''}`)
  }

  return (
    <div style={{ marginBottom: 40 }}>
      {(speaker || params.get('time') || therapeuticArea || params.get('tagName')) &&
        'Ver webinars:'}
      {speaker && (
        <Tooltip title="Eliminar filtro" placement="top">
          <Button
            size="small"
            color="default"
            variant="outlined"
            onClick={() => removeUserFromFilter('speakerID')}
            endIcon={<Close>send</Close>}
            style={{ display: 'inline-flex', marginLeft: 10, marginBottom: 7 }}
          >
            {speaker?.lastName}, {speaker?.name}
          </Button>
        </Tooltip>
      )}
      {params.get('time') && (
        <Tooltip title="Eliminar filtro" placement="top">
          <Button
            color="default"
            variant="outlined"
            onClick={() => removeUserFromFilter('time')}
            endIcon={<Close>send</Close>}
            style={{ display: 'inline-flex', marginLeft: 10, marginBottom: 7 }}
            size="small"
          >
            {params.get('time') === 'past' ? 'ANTERIORES' : 'PRÓXIMOS'}
          </Button>
        </Tooltip>
      )}
      {therapeuticArea?.name && (
        <Tooltip title="Eliminar filtro" placement="top">
          <Button
            color="default"
            variant="outlined"
            onClick={() => removeUserFromFilter('therapeutic-area')}
            endIcon={<Close>send</Close>}
            style={{ display: 'inline-flex', marginLeft: 10, marginBottom: 7 }}
            size="small"
          >
            {therapeuticArea?.name}
          </Button>
        </Tooltip>
      )}
      {params.get('tagName') && (
        <Tooltip title="Eliminar filtro" placement="top">
          <Button
            color="default"
            variant="outlined"
            onClick={() => removeUserFromFilter('tagName')}
            endIcon={<Close>send</Close>}
            style={{
              display: 'inline-flex',
              marginLeft: 10,
              marginBottom: 7,
              textTransform: 'uppercase',
            }}
            size="small"
          >
            {params.get('tagName')}
          </Button>
        </Tooltip>
      )}
    </div>
  )
}

export { AppliedFilters }
