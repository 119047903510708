import React, { useContext, useState } from 'react'
import {
  Button,
  Container,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
} from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu'
import logo from '@assets/images/logo-tfc.png'
import { AuthContext, LoginContext } from '@contexts'
import { useStyles } from './styles'
import { UserNavItems, ActividadNavItems } from './components'
import { LogOutModal, UserProfileModal } from './components/UserNavItems/components'

const NavBar = () => {
  const classes = useStyles()

  const [isNavOpen, SetIsNavOpen] = useState(false)
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
  const [isUserProfileModalOpen, setIsUserProfileModalOpen] = useState(false)

  const { isAuthenticated, userLoggedIn } = useContext(AuthContext)
  const { setIsOpen } = useContext(LoginContext)
  
  /*
  const [anchorElActividad, setAnchorElActividad] = useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElActividad(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElActividad(null)
  } */

  const menuItems = [
    {
      text: 'Speakers',
      link: '/speakers',
    },
    {
      text: 'Nosotros',
      link: '/nosotros',
    },
  ]

  const RenderItems = () => (

      <List className={classes.navList}>
        <ActividadNavItems/>
        {menuItems.map(item => (
          <ListItem key={item.link} className={classes.navItem}>
            <NavLink to={item.link} className={`${classes.navLink}`}>
              {item.text}
            </NavLink>

          </ListItem>

        ))}
        {!isAuthenticated ? (
          <>
            <ListItem className={classes.navItem}>
              <NavLink to="/sign-up" className={`${classes.navLink}`}>
                Registrarse
              </NavLink>
            </ListItem>
            <ListItem className={classes.navItem}>
              <Button
                onClick={() => setIsOpen(true)}
                className={`${classes.navLink} ${classes.login ?? ''}`}
              >
                Login
              </Button>
            </ListItem>
          </>
        ) : (
          <UserNavItems
            handleUserProfileModalOpen={setIsUserProfileModalOpen}
            handleLogoutModalOpen={setIsLogoutModalOpen}
          />
        )}
      </List>

  )

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    SetIsNavOpen(open)
  }

  return (
    <nav className={classes.navBar}>
      <Container style={{ height: '100%' }}>
        <Grid container alignItems="center" className={classes.navBarWrapper}>
          <div>
            <NavLink to="/" className={classes.logo}>
              <img src={logo} alt="Espacio Oncología Logo" />
            </NavLink>
          </div>
          <Hidden mdUp>
            <IconButton
              aria-label="delete"
              className={classes.toggleMenu}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          <Hidden smDown>
            <div className={classes.navbarMenu}>
              <RenderItems />
            </div>
          </Hidden>

          <Hidden mdUp>
            <Drawer
              anchor="right"
              open={isNavOpen}
              onClose={toggleDrawer(false)}
              className={classes.drawer}
            >
              <RenderItems />
            </Drawer>
          </Hidden>
        </Grid>
      </Container>

      <UserProfileModal
        isOpen={isUserProfileModalOpen}
        userSelected={userLoggedIn!}
        handleClose={() => setIsUserProfileModalOpen(false)}
      />

      <LogOutModal isOpen={isLogoutModalOpen} handleClose={() => setIsLogoutModalOpen(false)} />
    </nav>
  )
}

export { NavBar }
