import React, { useContext, useEffect } from 'react'
import { Controller, useForm, SubmitHandler } from 'react-hook-form'
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import headerBackground from '@assets/images/speakers-banner.jpg'
import { Layout, Main, FormAlert, Header } from '@components'
import { useAuth } from '@hooks'
import { useGlobalStyles } from '@styles'
import { AuthContext, LoginContext } from '@contexts'

import { validationSchema } from './validations'
import { useStyles } from './styles'
import { RegisterFormType } from './types'
import { specialties } from './specialities'
import { countries } from './countries'

const headTitle = 'Registrate - Espacio Oncología / TAKEDA'
const headDescription =
  'Forma parte de Espacio Oncología. Registrate y accede a actualización científica enfocada en Mieloma Múltiple, Linfoma y Cáncer de Pulmón.'

const defaultValues = {
  title: '',
  name: '',
  lastName: '',
  email: '',
  country: '',
  password: '',
  password2: '',
  specialty: '',
  termsAndConditions: false,
}

export const SignUp = () => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  const { isAuthenticated } = useContext(AuthContext)
  const { setIsOpen } = useContext(LoginContext)

  const { register, errorMessage, isSuccess } = useAuth()

  const { push } = useHistory()

  const { control, handleSubmit, errors } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const onSubmit: SubmitHandler<RegisterFormType> = async ({
    password2,
    termsAndConditions,
    ...data
  }) => {
    register(data)
  }

  useEffect(() => {
    if (isAuthenticated) push('/')
  }, [isAuthenticated, push])

  return (
    <Layout headTitle={headTitle} headDescription={headDescription}>
      <Main showGoBack backgroundImage={headerBackground}>
        <Header title="REGISTRO DE USUARIOS" className={classes.header} />
        <Container className={classes.container}>
          {!isSuccess ? (
            <form onSubmit={handleSubmit(onSubmit)} className={classes.contentWrapper}>
              <Grid container spacing={1}>
                <Grid item sm={2} xs={5}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    margin="normal"
                    style={{ width: '100%' }}
                  >
                    <InputLabel id="minutes-label">Título</InputLabel>
                    <Controller
                      name="title"
                      control={control}
                      as={
                        <Select labelId="minutes-label" id="minutes" label="Título *">
                          <MenuItem value="Dra.">Dra.</MenuItem>
                          <MenuItem value="Dr.">Dr.</MenuItem>
                        </Select>
                      }
                    />
                  </FormControl>
                  {errors.title && <FormAlert>{errors.title.message}</FormAlert>}
                </Grid>
                <Grid item sm={5} xs={7}>
                  <Controller
                    name="name"
                    control={control}
                    as={
                      <TextField
                        label="Nombre *"
                        placeholder="Ingresar nombre"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        autoComplete="off"
                        size="small"
                      />
                    }
                  />
                  {errors.name && <FormAlert>{errors.name.message}</FormAlert>}
                </Grid>
                <Grid item sm={5} xs={12}>
                  <Controller
                    name="lastName"
                    control={control}
                    as={
                      <TextField
                        label="Apellido *"
                        placeholder="Ingresar apellido"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        autoComplete="off"
                        size="small"
                      />
                    }
                  />
                  {errors.lastName && <FormAlert>{errors.lastName.message}</FormAlert>}
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="email"
                    control={control}
                    as={
                      <TextField
                        label="Correo electrónico *"
                        type="email"
                        placeholder="Ingresar correo electrónico"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        autoComplete="off"
                        size="small"
                      />
                    }
                  />
                  {errors.email && <FormAlert>{errors.email.message}</FormAlert>}
                </Grid>

                <Grid item sm={6} xs={12}>
                  <Controller
                    name="password"
                    control={control}
                    as={
                      <TextField
                        label="Contraseña *"
                        placeholder="Ingresar contraseña"
                        type="password"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        autoComplete="off"
                        size="small"
                      />
                    }
                  />
                  {errors.password && <FormAlert>{errors.password.message}</FormAlert>}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Controller
                    name="password2"
                    control={control}
                    as={
                      <TextField
                        label="Repetir contraseña *"
                        placeholder="Ingresar nuevamente la contraseña"
                        type="password"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        autoComplete="off"
                        size="small"
                      />
                    }
                  />
                  {errors.password2 && <FormAlert>{errors.password2.message}</FormAlert>}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    margin="normal"
                    style={{ width: '100%' }}
                  >
                    <InputLabel id="minutes-label">Pais</InputLabel>
                    <Controller
                      name="country"
                      control={control}
                      defaultValue=""
                      as={
                        <Select labelId="minutes-label" id="minutes" label="Especialidad">
                          {countries.map(item => (
                            <MenuItem value={item.name}>{item.name}</MenuItem>
                          ))}
                        </Select>
                      }
                    />
                  </FormControl>
                  {errors.specialty && <FormAlert>{errors.specialty.message}</FormAlert>}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    margin="normal"
                    style={{ width: '100%' }}
                  >
                    <InputLabel id="minutes-label">Especialidad</InputLabel>
                    <Controller
                      name="specialty"
                      control={control}
                      defaultValue=""
                      as={
                        <Select labelId="minutes-label" id="minutes" label="Especialidad">
                          {specialties.map(specialty => (
                            <MenuItem value={specialty}>{specialty}</MenuItem>
                          ))}
                        </Select>
                      }
                    />
                  </FormControl>
                  {errors.specialty && <FormAlert>{errors.specialty.message}</FormAlert>}
                </Grid>
              </Grid>
              <div>
                <Controller
                  name="termsAndConditions"
                  control={control}
                  faultValues={false}
                  render={props => (
                    <FormControlLabel
                      label={
                        <>
                          Aceptar <Link to="/terms-and-conditions">términos y condiciones</Link> *
                        </>
                      }
                      control={
                        <Checkbox
                          checked={props.value}
                          onChange={e => props.onChange(e.target.checked)}
                          color="primary"
                        />
                      }
                    />
                  )}
                />
              </div>
              {errors.termsAndConditions && (
                <FormAlert>{errors.termsAndConditions.message}</FormAlert>
              )}
              {errorMessage && (
                <div
                  className={`${globalClasses.textCenter} ${globalClasses.mediumBottomSeparator}`}
                >
                  <FormAlert>{errorMessage}</FormAlert>
                </div>
              )}

              <Button type="submit" variant="contained" color="primary" className={classes.button}>
                Registrarme
              </Button>

              <div className={globalClasses.textCenter}>
                <Button onClick={() => setIsOpen(true)}>¿Ya tenés cuenta?</Button>
              </div>
            </form>
          ) : (
            <div className={classes.contentWrapper}>
              <Typography component="h3" variant="h3" align="center">
                ¡Email enviado!
              </Typography>
              <Typography component="p" align="center">
                Verificá tu correo electrónico en unos minutos y completa tu solicitud de
                activación.
              </Typography>
              <br />
              <Typography component="p" align="center">
                <small>
                  En caso de no recibirlo en la bandeja de entrada, asegurate de chequear la carpeta
                  SPAM
                </small>
              </Typography>
            </div>
          )}
        </Container>
      </Main>
    </Layout>
  )
}
