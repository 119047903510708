import * as yup from 'yup'

import { ERRORS } from '@constants'

export const validationSchema = yup.object().shape({
  // emailRemitente: yup.string().required(ERRORS.REQUIRED.DEFAULT).email(ERRORS.FORMAT.EMAIL),
  // emailDestinatario: yup.string().required(ERRORS.REQUIRED.DEFAULT).email(ERRORS.FORMAT.EMAIL),
  request: yup.string().required(ERRORS.REQUIRED.DEFAULT),
  therapeuticAreasId: yup.number().required(ERRORS.REQUIRED.DEFAULT),
  // asunto: yup.string(),
})
