import { TherapeuticArea, Webinar } from '@types'
import { api } from '@utils'

export interface GetWebinarsPayload {
  offset: number
  limit: number
  title: string
  tagName: string
  speakerName: string
  speakerID: string
  isSalient: boolean
  isActive: boolean
  orderBy: boolean
  therapeuticAreaId: number
  pastWebinars: boolean
  nextWebinars: boolean
}

export interface GetWebinarsResponse {
  count: number
  page: number
  webinars: Webinar[]
  therapeuticArea: TherapeuticArea
}

export enum OrderByEnum {
  upcoming = 'upcoming',
}

const getWebinars = async (
  _: string,
  {
    offset = 0,
    limit = 10,
    title,
    tagName,
    speakerName,
    speakerID,
    isSalient,
    therapeuticAreaId,
    pastWebinars,
    nextWebinars,
  }: GetWebinarsPayload,
): Promise<GetWebinarsResponse> => {
  const params = {
    title: title || undefined,
    tagName: tagName || undefined,
    speakerName: speakerName || undefined,
    speakerID: speakerID || undefined,
    therapeuticAreaId: therapeuticAreaId || undefined,
    pastWebinars: pastWebinars || undefined,
    nextWebinars: nextWebinars || undefined,
    offset: offset * limit,
    isActive: true,
    orderBy: OrderByEnum.upcoming,
    limit,
    isSalient,
  }

  const response = await api.get<GetWebinarsResponse>('/webinars', { params })
  return response.data
}

export const WebinarsGlobalApi = { getWebinars }
