import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  item: {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    borderRadius: 30,
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.3)',
    backgroundColor: '#fff',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: '.6em',
    overflow: 'hidden',
    padding: 25,
    [theme.breakpoints.up('lg')]: {
      height: '40vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: 210,
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(50vw - 32px)',
    },
  },
  title: {
    fontWeight: 'bold',
    color: '#FFF',
    // textShadow: '1px 1px 3px rgba(0,0,0,1)',
    lineHeight: 1.21,
    zIndex: 1,
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.4vh',
    },
  },
}))

export { useStyles }
