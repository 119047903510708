import { makeStyles } from '@material-ui/core'

import bgBottom from '@assets/images/bg-bottom.png'

const useStyles = makeStyles({
  main: {
    '&::before': {
      backgroundImage: `url(${bgBottom})`,
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  logo: {
    marginBottom: 40,
  },
  contentWrapper: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    maxWidth: 420,
    margin: '20px auto',
    padding: 26,
    borderRadius: 35,
    boxShadow: '5px 5px 50px 0 rgba(0, 0, 0, 0.16)',
    textAlign: 'center',
    '& a': {
      textDecoration: 'none',
      color: '#333',
    },
  },
  button: {
    width: 200,
    margin: '20px auto',
    display: 'block',
  },
  submitAlert: {
    textAlign: 'center',
    margin: 20,
  },
})

export { useStyles }
