import { makeStyles } from '@material-ui/core'

import { COLORS } from '@styles'

const useStyles = makeStyles(theme => ({
  search: {
    display: 'flex',
    color: COLORS.GRAY_DARK,
    marginTop: 30,
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      marginLeft: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  navLink: {
    fontSize: 14,
    fontWeight: 700,
    margin: '0 15px',
    padding: '5px 0',
    textDecoration: 'none',
    color: COLORS.GRAY_HEADER,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10,
      padding: 0,
      margin: '10px 0',
    },
    '&.active': {
      [theme.breakpoints.up('md')]: {
        borderBottom: `2px solid ${theme.palette.primary.main}`,
      },
      [theme.breakpoints.down('sm')]: {
        borderLeft: `3px solid ${theme.palette.primary.main}`,
      },
    },
  },
  searchInput: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },
}))

export { useStyles }
