import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  main: {
    marginTop: 0,
  },
  modal: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: 30,
  },
  logo: {
    marginBottom: 40,
  },
  verifiedAccount: {
    marginBottom: 40,
  },
  contentWrapper: {
    backgroundColor: 'white',
    width: 420,
    maxWidth: '90%',
    margin: '0 auto 20vh',
    padding: 26,
    borderRadius: 35,
    boxShadow: '5px 5px 50px 0 rgba(0, 0, 0, 0.16)',
    textAlign: 'center',
    '& p': {
      fontSize: '12px',
      margin: '5px 0',
    },
  },
  link: { textDecoration: 'none', color: '#333' },
  submitAlert: {
    textAlign: 'center',
    margin: 20,
  },
  button: {
    width: 200,
    margin: '20px auto',
    display: 'block',
  },
  signUpButton: {
    width: 180,
    margin: '5px 5px 5px 15px',
    display: 'inline-block',
    background: 'white',
    border: '1px solid #ff0202',
    color: '#ff0202',
    padding: '3px 8px',
    borderRadius: '20px',
    textDecoration: 'none',
  },
})

export { useStyles }
