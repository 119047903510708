import React, { FC } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import { QUERY_KEYS, TherapeuticAreasGlobalApi } from '@api'

import { useStyle } from './styles'

export const CategoriesFilter: FC = () => {
  const params = new URLSearchParams(window.location.search)

  const classes = useStyle()

  const { data: therapeuticAreas } = useQuery(
    [QUERY_KEYS.THERAPEUTIC_AREAS],
    TherapeuticAreasGlobalApi.getTherapeuticAreas,
  )

  const isActive = (taId: number) => {
    if (!params.get('therapeutic-area') && taId === 0) return true
    return params.get('therapeutic-area') === taId.toString()
  }

  return (
    <>
      <Link to="/speakers" className={`${classes.navLink} ${isActive(0) ? 'active' : ''}`}>
        TODAS LAS ÁREAS
      </Link>
      {therapeuticAreas?.map(therapeuticArea => {
        return (
          <Link
            to={() => {
              params.set('therapeutic-area', therapeuticArea.id.toString())
              return {
                pathname: '/speakers',
                search: `?${params.toString()}`,
              }
            }}
            className={`${classes.navLink} ${isActive(therapeuticArea.id) ? 'active' : ''}`}
            key={therapeuticArea.name}
          >
            {therapeuticArea.name}
          </Link>
        )
      })}
    </>
  )
}
