import { UserCache } from '@types'
import { api } from '@utils'

type addRegistrantPayload = {
  webinarId: number
  registrant: UserCache
  timeZone: string
}

const addRegistrant = async ({ webinarId, registrant, timeZone }: addRegistrantPayload) => {
  try {
    const response = await api.post(`/webinars/${webinarId}/registrants`, {
      registrant: { userId: registrant.id, timeZone },
    })
    return response.data
  } catch (err) {
    throw err.response.data.error
  }
}

export const WebinarApi = { addRegistrant }
