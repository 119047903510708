import { makeStyles } from '@material-ui/core'

export { COLORS } from './colors'

const layoutStyles = makeStyles({
  button: {
    color: 'white',
    padding: '6px 16px',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: 1.75,
    borderRadius: 4,
    textTransform: 'uppercase',
    textDecoration: 'none',
  },
  inline: {
    display: 'inline-style',
  },
})

const textErrorSize = 13.7

const useGlobalStyles = makeStyles(theme => ({
  mediumBottomSeparator: {
    marginBottom: 20,
  },
  mediumTopSeparator: {
    marginTop: 20,
  },
  form: {
    width: '100%',
  },
  formGroup: {
    width: '100%',
    marginBottom: 20,
    '& > *': {
      flexGrow: 1,
    },
    '&::before': {
      borderColor: theme.palette.grey[100],
    },
  },
  formControl: {
    '& input': {
      width: '100%',
    },
  },
  inline: {
    display: 'inline',
  },
  progressBar: {
    position: 'fixed',
    zIndex: 9999,
    top: 0,
    right: 0,
    left: 0,
  },
  modal: {
    backgroundColor: 'transparent',
  },
  modalCard: {
    position: 'relative',
    backgroundColor: '#ffffff',
    width: 628,
    maxWidth: '95%',
    margin: '50px auto',
    padding: '26px 20px',
    boxShadow: '5px 5px 50px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: 35,
  },
  modalCloseIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: '20px',
    right: '30px',
    zIndex: 10,
    color: theme.palette.primary.light,
  },
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
    outline: 'none',
  },
  textError: {
    color: theme.palette.error.dark,
    fontSize: textErrorSize,
  },
  textWarning: {
    color: theme.palette.warning.dark,
    fontSize: textErrorSize,
  },
  textSuccess: {
    color: theme.palette.success.dark,
    fontSize: textErrorSize,
  },
  textCenter: {
    textAlign: 'center',
  },
}))

export { layoutStyles, useGlobalStyles }
