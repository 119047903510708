import { makeStyles } from '@material-ui/core'

import { LAYOUT } from '@constants'

import { CARD, SCROLL_WRAPPER } from '../constants'

const { CONTAINER } = LAYOUT

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    marginBottom: 0,
    paddingBottom: 0,
  },
  main: {
    minHeight: 480,
    // [theme.breakpoints.up('md')]: {
    //   height: `calc(100vh - ${NAV.HEIGHT + FOOTER.HEIGHT}vh)`,
    // },
  },
  headerTitle: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.26rem',
    },
  },
  scorller: {
    overflowX: 'auto',
    overflowY: 'hidden',
    padding: '3.8vh 0',
    '&::-webkit-scrollbar': {
      background: 'transparent',
      height: 0,
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'green',
    },
  },
  gridContainer: {
    minHeight: 480,
    '& > .webinar': {
      width: '100%',
      marginBottom: CONTAINER.PADDING,
    },
    [theme.breakpoints.up('md')]: {
      display: 'inline-flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      height: '62vh',
      '& > .webinar': {
        margin: 0,
        '&.huge': {
          width: `${CARD.HUGE.WIDTH}vw`,
          height: `${CARD.HUGE.HEIGHT}vh`,
          minHeight: 400,
          [theme.breakpoints.up('md')]: {
            minWidth: 1000,
          },
        },
        '&.adapt': {
          width: ' 100%',
        },
        '&.big': {
          width: `${CARD.BIG.WIDTH}vw`,
          height: `${CARD.BIG.HEIGHT}vh`,
          minHeight: 400,
        },
        '&.small': {
          width: `${CARD.SMALL.WIDTH}vw`,
          height: `${CARD.SMALL.HEIGHT}vh`,
          minHeight: 186,
        },
        '&:nth-child(n + 2)': {
          marginLeft: `${SCROLL_WRAPPER.SPACING.HORIZONTAL}vw`,
        },
        '&:nth-of-type(2n + 3)': {
          marginBottom: `${SCROLL_WRAPPER.SPACING.VERTICAL}vw`,
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      width: '100% !important',
      paddingLeft: CONTAINER.PADDING,
      paddingRight: CONTAINER.PADDING,
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 'calc( (100vw - 1200px) / 2 + 24px)',
      marginRight: 'calc( (100vw - 1200px) / 2 + 24px)',
    },
  },
}))

export { useStyles }
