import React, { useEffect, useState, useContext } from 'react'
import { Container, Grid } from '@material-ui/core'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router'
import InfiniteScroll from 'react-infinite-scroller'

import { QUERY_KEYS } from '@api'
import { Header, Layout, Main, ModalLastWebinar } from '@components'
import headerBackground from '@assets/images/speakers-banner.jpg'
import { Speaker } from '@types'

import { NavFilter, SpeakerCard } from './components'
import { SpeakersApi } from './api'
import { OngoingWebinarContext } from '@contexts'

export const Speakers = () => {
  const params = new URLSearchParams(window.location.search)

  const { pathname, search } = useLocation()

  const [limit] = useState(9)
  const [offset, setOffset] = useState(0)
  const [speakers, setSpeakers] = useState<Speaker[]>([])
  const [speakersQty, setSpeakersQty] = useState(0)

  const [lockLoadItems, setLockLoadItems] = useState(false)
  const [currentPath, setCurrentPath] = useState('')

  useQuery(
    [
      QUERY_KEYS.SPEAKERS,
      {
        offset,
        limit,
        name: params.get('search'),
        lastName: params.get('search'),
        specialty: params.get('search'),
        therapeuticAreaId: params.get('therapeutic-area'),
      },
    ],
    SpeakersApi.getSpeakers,
    {
      onSuccess: ({ speakers: speakersData, count }) => {
        setSpeakers(prevState => [...prevState, ...speakersData])
        setSpeakersQty(count)
        setLockLoadItems(false)
      },
      refetchOnWindowFocus: false,
    },
  )

  const loadItems = () => {
    if (!lockLoadItems) {
      setLockLoadItems(true)
      setOffset(prevState => prevState + 1)
    }
  }

  useEffect(() => {
    if (`${pathname}${search}` !== currentPath) {
      if (offset !== 0) setOffset(0)
      if (speakers.length > 0) setSpeakers([])
      setCurrentPath(`${pathname}${search}`)
    }
  }, [currentPath, offset, pathname, search, speakers.length])

  const { ongoingWebinar, showOngoingWebinar, setShow } = useContext(OngoingWebinarContext)

  return (
    <Layout>
      {showOngoingWebinar && (
        <ModalLastWebinar ongoingWebinar={ongoingWebinar} handleClose={() => setShow(false)} />
      )}
      <Main showGoBack backgroundImage={headerBackground}>
        <Header title="SPEAKERS">
          <NavFilter />
        </Header>
        <Container>
          {speakers.length > 0 && (
            <InfiniteScroll
              pageStart={offset}
              loadMore={loadItems}
              hasMore={offset * limit <= speakersQty}
              threshold={10}
              style={{ display: 'flex', flexWrap: 'wrap', overflow: 'unset' }}
            >
              <Grid container spacing={4}>
                {speakers.map(speaker => (
                  <Grid key={speaker.id} item xs={12} sm={6} md={4}>
                    <SpeakerCard speaker={speaker} />
                  </Grid>
                ))}
              </Grid>
            </InfiniteScroll>
          )}
        </Container>
      </Main>
    </Layout>
  )
}
