import { makeStyles } from '@material-ui/core'

import { COLORS } from '@styles'

const avatarSize = 110

const useStyle = makeStyles(theme => ({
  container: {
    paddingTop: 142,
  },
  card: {
    width: '100%',
    maxWidth: 628,
    margin: '0 auto',
    padding: 26,
    borderRadius: 35,
    boxShadow: '5px 5px 50px 0 rgba(0, 0, 0, 0.16)',
  },
  header: {
    padding: 0,
  },
  name: {
    fontSize: '1.4rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
    fontWeight: 700,
  },
  specialty: {
    color: COLORS.GRAY,
    fontSize: 14,
    fontWeight: 700,
  },
  avatar: {
    height: avatarSize,
    width: avatarSize,
  },
  content: {
    padding: 0,
  },
  biography: {
    fontSize: '1.36rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
    fontWeight: 300,
    lineHeight: 1.4,
  },
  spinner: {
    justifyContent: 'center',
    display: 'flex',
  },
}))

export { useStyle }
