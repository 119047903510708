import { QUERY_KEYS } from '@api'
import { useLogoutByToken } from '@hooks'
import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

api.defaults.headers.common['Content-Type'] = 'application/json'
api.defaults.headers.common.Accept = 'application/json'

const setAuthHeader = (token: string) => {
  api.defaults.headers.Authorization = `Bearer ${token}`
}

const removeAuthHeader = () => {
  delete api.defaults.headers.Authorization
}

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {    
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const idToken = window.localStorage.getItem(QUERY_KEYS.ID_TOKEN) || false
        if (!idToken) return Promise.reject(error)
        
        const response = await axios.post(`${process.env.REACT_APP_API_URL}auth/login/refresh-token`, {idToken});
        
        const token = response.data.sessionToken
        localStorage.setItem(QUERY_KEYS.SESSION_TOKEN, token);
        setAuthHeader(token)
        return api(originalRequest);
      } catch (error) {
        console.log('Refresh token error:',error);
        const idToken = window.localStorage.getItem(QUERY_KEYS.ID_TOKEN) || ''
        useLogoutByToken(idToken)
      }
    }
    return Promise.reject(error);
  }
);

export { api, setAuthHeader, removeAuthHeader }
