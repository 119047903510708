import { createMuiTheme } from '@material-ui/core'

import { LAYOUT } from '@constants'
import { COLORS } from '@styles'

const {
  CONTAINER: { XL, LG, MD, SM, XS },
} = LAYOUT

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1400,
    },
  },

  overrides: {
    MuiOutlinedInput: {
      root: {
        backgroundColor: 'white',
        height: 40,
        borderRadius: 20,
      },
    },

    MuiCssBaseline: {
      '@global': {
        body: {
          margin: 0,
          fontFamily: `'Montserrat', sans-serif`,
          fontSize: 12,
          backgroundColor: '#FFF',
          color: '#333',
        },
        '*, *::before, *::after': {
          boxSizing: 'border-box',
        },
      },
    },

    MuiButton: {
      outlined: {
        whiteSpace: 'nowrap',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: '#333',
        borderRadius: 20,
        color: '#333',
        display: 'inline-block',
        padding: '7px 16px',
      },
      contained: {
        backgroundColor: COLORS.PRIMARY,
        color: '#FFF',
        whiteSpace: 'nowrap',
        borderRadius: 20,
        display: 'inline-block',
        padding: '7px 16px',
      },
      outlinedPrimary: {
        borderWidth: 2,
        borderColor: '#FFF',
        color: '#FFF',
        '&:hover': {
          backgroundColor: 'transparent',
          borderWidth: 2,
          borderColor: '#FFF',
        },
      },
      outlinedSecondary: {
        borderWidth: 2,
        borderColor: COLORS.PRIMARY,
        color: COLORS.PRIMARY,
        '&:hover': {
          backgroundColor: 'transparent',
          borderWidth: 2,
        },
      },
    },

    MuiContainer: {
      maxWidthXl: {
        maxWidth: XL,
      },
      maxWidthLg: {
        maxWidth: LG,
      },
      maxWidthMd: {
        maxWidth: MD,
      },
      maxWidthSm: {
        maxWidth: SM,
      },
      maxWidthXs: {
        maxWidth: XS,
      },
    },

    MuiPaper: {
      root: {
        color: '#333',
      },
    },
    MuiTypography: {
      h1: {
        color: '#535353',
        width: '100%',
        height: 386,
        margin: '0 auto',
        display: 'flex',
        padding: 60,
        fontSize: 43,
        textAlign: 'center',
        alignItems: 'center',
        fontWeight: 700,
        textTransform: 'uppercase',
        justifyContent: 'center',
      },
      h2: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        marginBottom: 20,
      },
      h3: {
        fontSize: '1.3rem',
        marginBottom: 20,
      },
      h4: {
        fontSize: '1.1rem',
        marginBottom: 20,
      },
    },

    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
      },
    },
  },

  palette: {
    text: {
      primary: '#535353',
    },
    primary: {
      main: '#ff0202',
    },
  },
  // TODO no puedo importarlo, tira error de type
  typography: {
    fontSize: 12,
    fontFamily: `'Montserrat', sans-serif`,
    button: {
      textTransform: 'none',
    },
  },
})

export { theme }
