import React, { FC } from 'react'
import { Box } from '@material-ui/core'

import { useGlobalStyles } from '@styles'

interface Props {
  type?: 'textError' | 'textWarning' | 'textSuccess'
}

const FormAlert: FC<Props> = ({ children, type = 'textError' }) => {
  const globalClasses = useGlobalStyles()

  return (
    <Box component="span" className={globalClasses[type]}>
      {children}
    </Box>
  )
}

export { FormAlert }
