import { makeStyles } from '@material-ui/core'
import bgBottom from '@assets/images/bg-bottom.png'

const useStyles = makeStyles(theme => ({
  main: {
    minHeight: 480,
    paddingTop: 0,
    '&::before': {
      [theme.breakpoints.up('md')]: {
        backgroundImage: `url(${bgBottom})`,
      },
    },
  },
  container: {
    position: 'relative',
    marginTop: -100,
    [theme.breakpoints.up('lg')]: {
      maxWidth: '62.8vw',
    },
    [theme.breakpoints.down('lg')]: {
      position: 'relative',
      zIndex: 1,
      marginTop: -80,
    },
  },
  taCardsTitle: {
    display: 'inline-block',
    backgroundColor: 'rgba(0,0,0,0.5)',
    fontSize: '.88rem',
    fontWeight: 600,
    color: '#FFF',
    padding: '6px 12px',
    borderRadius: 10,
    margin: '1.6vh .6vh',
  },
}))

export { useStyles }
