import { UserCache } from '@types'
import { api } from '@utils'

import { LoginPayload, LoginResponse, LogOutPayload, RegisterPayload } from './types'

const login = async (payload: LoginPayload) => {
  try {
    const response = await api.post<LoginResponse>('/auth/login', payload)
    return response.data
  } catch (err) {
    throw err.response.data.error
  }
}

const loginWithToken = async () => {
  try {
    const response = await api.post<{ user: UserCache }>('auth/login/token')
    return response.data.user
  } catch (err) {
    throw err.response.data.error
  }
}

const register = async (payload: RegisterPayload) => {
  try {
    const response = await api.post('/auth/register', { user: payload })
    return response.data
  } catch (err) {
    throw err.response.data.error
  }
}

const logout = async (payload: LogOutPayload) => {
  try {
    const response = await api.post('/auth/logout', payload)
    return response.data
  } catch (err) {
    throw err.response.data.error
  }
}

export const AuthApi = { login, register, loginWithToken, logout }
