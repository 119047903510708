import React, { useContext } from 'react'
import { Button, Container, Hidden, makeStyles } from '@material-ui/core'

import { Header, Layout, Main, ModalLastWebinar } from '@components'
import background from '@assets/images/bg-nosotros.jpg'
import valores from '@assets/images/takedaismo.jpg'
import logoTakeda from '@assets/images/logo-takeda.png'

import { useStyles } from '../styles'
import { OngoingWebinarContext } from '@contexts'

const useCustomClasses = makeStyles(theme => ({
  header: {
    marginBottom: 0,
  },
  title: {
    marginBottom: 0,
  },
  takedaLogo: {
    [theme.breakpoints.down('sm')]: {
      width: 120,
    },
  },
  button: {
    borderRadius: 13,
    marginTop: 10,
    marginRight: 10,
  },
}))

const headTitle = 'Nosotros - Espacio Oncologia / TAKEDA'
const headDescription =
  'Líderes de opinión, expertos y médicos especialistas comparten casos clínicos y actualizaciones científicas sobre Linfoma, Mieloma Múltiple y Cáncer de pulmón.'

const AboutUs = () => {
  const classes = useStyles()
  const customClasses = useCustomClasses()

  const { ongoingWebinar, showOngoingWebinar, setShow } = useContext(OngoingWebinarContext)

  return (
    <Layout headTitle={headTitle} headDescription={headDescription}>
      {showOngoingWebinar && (
        <ModalLastWebinar ongoingWebinar={ongoingWebinar} handleClose={() => setShow(false)} />
      )}
      <Main showGoBack className={classes.main} backgroundImage={background}>
        <Header
          title="NOSOTROS"
          className={customClasses.header}
          titleClass={customClasses.title}
        />
        <Container>
          <h3>Misión:</h3>
          <p style={{ marginBottom: 0 }}>
            Takeda es una compañía con valores sólidos, comprometida en promover una mejor salud y
            un mejor futuro para las personas en todo el mundo.
          </p>
          <p>
            <strong>Los valores de Takeda definen nuestro propósito</strong>
          </p>
          <h3>Valores:</h3>
          <p>
            <strong>Integridad, Justicia, Honestidad y Perseverancia,</strong> lo que llamamos{' '}
            <strong>“Takeda-ismo”,</strong> representan la base en la que se sustenta toda nuestra
            actividad pasada, presente y futura. Sirven para recordarnos permanentemente que nuestro
            rol primordial en la sociedad es servir al paciente y trabajar activamente y con pasión
            en la búsqueda de nuevas alternativas para el cuidado de la salud.
          </p>
          <img src={valores} alt="Nuestros valores" style={{ width: '100%', marginBottom: 40 }} />
          <img
            src={logoTakeda}
            alt="Logo Takeda"
            style={{ display: 'block', margin: '10px auto', width: 200 }}
            className={customClasses.takedaLogo}
          />
          <p style={{ fontSize: '1.2rem', fontWeight: 700, textAlign: 'center' }}>
            Better Health,
            <Hidden mdUp>
              <br />
            </Hidden>{' '}
            Brighter Future
          </p>
          <p>
            Podés conocer más acerca de nosotros ingresando en:
            <br />
            <Button
              component="a"
              href="https://www.takeda.com/es-ar"
              target="_blank"
              variant="contained"
              color="primary"
              className={customClasses.button}
            >
              TAKEDA Argentina
            </Button>
          </p>
          <p>
            Para conocer sobre nuestras terapias:
            <br />
            <Button
              component="a"
              href="https://www.shirecontent.com/PI/PDFs/ADCETRIS_AR_ESP.pdf"
              target="_blank"
              variant="contained"
              color="primary"
              className={customClasses.button}
              style={{ backgroundColor: 'rgb(35, 107, 144)' }}
            >
              ADCETRIS
            </Button>
            <Button
              component="a"
              href="https://www.shirecontent.com/PI/PDFs/MYVITLA_AR_ESP.pdf"
              target="_blank"
              variant="contained"
              color="primary"
              className={customClasses.button}
              style={{ backgroundColor: 'rgb(232, 124, 0)' }}
            >
              MYVITLA
            </Button>
            <Button
              component="a"
              href="https://www.shirecontent.com/PI/PDFs/ALUNBRIG_AR_ESP.pdf"
              target="_blank"
              variant="contained"
              color="primary"
              className={customClasses.button}
              style={{ backgroundColor: 'rgb(90, 181, 233)' }}
            >
              ALUNBRIG
            </Button>
          </p>
          <p>
            Por cualquier consulta o información adicional escribinos a:
            <br />
            <a
              href="mailto:contacto@espaciooncologia.com"
              rel="noopener noreferrer"
              target="_blank"
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              contacto@espaciooncologia.com
            </a>
          </p>
        </Container>
      </Main>
    </Layout>
  )
}

export { AboutUs }
