import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  carousel: {
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      height: '48vh',
    },
    '& > div:last-child': {
      position: 'absolute',
      zIndex: 1,
      top: 20,
      '& > svg': {
        color: '#FFF',
        opacity: 0.6,
        '&.Carousel-active-18': {
          opacity: 1,
        },
      },
    },
  },
}))

export { useStyles }
