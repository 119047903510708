import React, { FC } from 'react'
import { Switch } from 'react-router-dom'
import { Route, RouteComponentProps } from 'react-router'

import { Webinars } from './Webinars'
import { WebinarDetails } from './WebinarDetails'
import { TherapeuticAreas } from './TherapeuticAreas'

export const WebinarsRouter: FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}/therapeutic-areas/:id`} component={TherapeuticAreas} />
      <Route exact path={`${match.path}/:id`} component={WebinarDetails} />
      <Route path={match.path} component={Webinars} />
    </Switch>
  )
}
