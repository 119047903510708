import React, { useContext, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ThemeProvider, CssBaseline } from '@material-ui/core'

import { ScrollToTop } from '@components'
import { AuthContext, BackdropProvider, LoginProvider, OngoingWebinarProvider } from '@contexts'
import { theme } from '@theme'
import { useAuth, useLocalStorage } from '@hooks'
import { removeAuthHeader, setAuthHeader } from '@utils'
import { QUERY_KEYS } from '@api'

import { Home } from './Home'
import { SpeakersRouter } from './SpeakersRouter'
import { AboutUs } from './AboutUs'
import { WebinarsRouter } from './WebinarsRouter'
import { PodcastsRouter } from './PodcastsRouter'
import { PrivacyPolicies } from './PrivacyPolicies'
import { TermsAndConitions } from './TermsAndConditions'
import { SignUp } from './SignUp'
import { FormMedica } from './FormMedica'
import { Login } from './Login'
import { RecoveryPassword } from './RecoveryPassword'

function App() {
  const [sessionToken] = useLocalStorage<string>(QUERY_KEYS.SESSION_TOKEN)

  const { loginWithToken } = useAuth()
  const { userLoggedIn } = useContext(AuthContext)

  useEffect(() => {
    if (sessionToken) {
      setAuthHeader(sessionToken)
      if (!userLoggedIn) {
        loginWithToken()
      }
    } else {
      removeAuthHeader()
    }
  }, [loginWithToken, sessionToken, userLoggedIn])

  return (
    <ThemeProvider theme={theme}>
      <BackdropProvider>
        <CssBaseline />
        <Router>
          <ScrollToTop>
            <OngoingWebinarProvider>
              <LoginProvider>
                <Switch>
                  <Route path="/webinars" component={WebinarsRouter} />
                  <Route path="/podcasts" component={PodcastsRouter} />
                  <Route path="/speakers" component={SpeakersRouter} />
                  <Route path="/nosotros" component={AboutUs} />
                  <Route path="/privacy-policies" component={PrivacyPolicies} />
                  <Route path="/terms-and-conditions" component={TermsAndConitions} />
                  <Route path="/login" component={Login} />
                  <Route path="/sign-up" component={SignUp} />
                  <Route path="/form-medica" component={FormMedica} />
                  <Route path="/recovery-password" component={RecoveryPassword} />
                  <Route path="/" component={Home} />
                </Switch>
              </LoginProvider>
            </OngoingWebinarProvider>
          </ScrollToTop>
        </Router>
      </BackdropProvider>
    </ThemeProvider>
  )
}

export default App
