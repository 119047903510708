import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '.7rem',
    border: '1px red',
  },
  root: {
    top: '15%',
    left: 0,
    right: 0,
    height: '12.2vh',
    zIndex: 101,
    position: 'fixed',
    minHeight: '60px',
  },
  navBarWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    margin: '0 auto',
    backgroundColor: '#FFF',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
    borderRadius: '15px',
    width: '50%',
    marginLeft: '50%',
    paddingBottom: '20px',
  },
  navBarRow: {
    display: 'flex',
    height: '100%',
    margin: '0 auto',
  },
  left: {
    flexGrow: 1,
  },
  iconRoot: {
    margin: '1%',
    textAlign: 'center',
  },
  icon: {
    width: '40%',
  },
  iconMb: {
    width: '20%',
  },
  arrowRight: {
    marginLeft: '2px',
    border: 'solid white',
    borderWidth: '0 3px 3px 0',
    display: 'inline-block',
    padding: '.3em',
    transform: 'rotate(-45deg)',
    webkitTransform: 'rotate(-45deg)',
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: '10px',
  },
  webinarBox: {
    textAlign: 'left',
    padding: '0 5px 0 20px',
  },
  webinarBoxMb: {
    textAlign: 'center',
    margin: '0px 30px',
  },
  title: {
    fontSize: '1em',
    margin: '10px 0',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  titleMb: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    lineHeight: '27px',
    marginBottom: '20px',
  },
  webinarText: {
    margin: '0 0 10px 0',
  },
  webinarTextMb: {
    fontSize: '1em',
    margin: '-20px 0 40px 0',
  },
  webinarTitle: {
    color: '#f44336',
  },
  closebtnWeb: {
    cursor: 'pointer',
    zIndex: 10,
    color: 'black',
    margin: '5px 0 0 95%',
  },
  closebtn: {
    cursor: 'pointer',
    position: 'absolute',
    top: '20px',
    right: '30px',
    zIndex: 10,
    color: 'black',
  },
  closebtnHover: {
    color: 'black',
  },
  buttonSection: {
    textAlign: 'center',
  },
  buttonSectionMb: {
    textAlign: 'center',
  },
  redButton: {
    backgroundColor: '#f44336',
    border: 'none',
    color: 'white',
    padding: '10px 20px',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '.8em',
    margin: '4px 2px',
    cursor: 'pointer',
    borderRadius: '15px',
    minHeight: '45px',
    minWidth: '120px',
  },
  redButtonMb: {
    backgroundColor: '#f44336',
    border: 'none',
    color: 'white',
    padding: '5px 40px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '1.2em',
    margin: '4px 2px',
    cursor: 'pointer',
    borderRadius: '15px',
  },
}))

export { useStyles }
