import React, { FC, useEffect, useState, useContext } from 'react'
import { Container } from '@material-ui/core'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router'

import { QUERY_KEYS, WebinarsGlobalApi } from '@api'
import { Header, Layout, Main, ModalLastWebinar } from '@components'
import { Webinar } from '@types'
import headerBackground from '@assets/images/webinars-banner.jpg'

import { useStyles } from './styles'
import { OngoingWebinarContext } from '@contexts'

const headTitle = 'Actividades - Espacio Oncología / TAKEDA'
const headDescription =
  'Charlas, actividades y eventos de actualización científica enfocada en oncología. Mieloma Múltiple - Linfoma - Cancer de Pulmón.'

export const Podcasts: FC = () => {
  const classes = useStyles()

  const { pathname, search } = useLocation()

  const params = new URLSearchParams(window.location.search)

  const [offset, setOffset] = useState(0)
  const [limit] = useState(10)
  const [webinars, setWebinars] = useState<Webinar[]>([])
  const [webinarsCount, setWebinarsCount] = useState(0)

  const [lockLoadItems, setLockLoadItems] = useState(false)
  const [currentPath, setCurrentPath] = useState('')

  useQuery(
    [
      QUERY_KEYS.WEBINARS,
      {
        offset,
        limit,
        title: params.get('search'),
        speakerName: params.get('speakerName') ?? params.get('search'),
        speakerID: params.get('speakerID')!,
        tagName: params.get('tagName') ?? params.get('search'),
        therapeuticAreaId: params.get('therapeutic-area'),
        pastWebinars: params.get('time') === 'past',
        nextWebinars: params.get('time') === 'future',
      },
    ],
    WebinarsGlobalApi.getWebinars,
    {
      onSuccess: ({ count, webinars: webinarData }) => {
        setWebinarsCount(count)
        setWebinars(prevState => [...prevState, ...webinarData])
        setLockLoadItems(false)
      },
      refetchOnWindowFocus: false,
    },
  )

  /* const loadItems = () => {
    if (!lockLoadItems) {
      setLockLoadItems(true)
      setOffset(prevState => prevState + 1)
    }
  } */

  useEffect(() => {
    if (`${pathname}${search}` !== currentPath) {
      if (offset !== 0) setOffset(0)
      if (webinars.length > 0) setWebinars([])
      setCurrentPath(`${pathname}${search}`)
    }
  }, [currentPath, offset, pathname, search, webinars.length])

  const { ongoingWebinar, showOngoingWebinar, setShow } = useContext(OngoingWebinarContext)

  return (
    <Layout headTitle={headTitle} headDescription={headDescription}>
      {showOngoingWebinar && (
        <ModalLastWebinar ongoingWebinar={ongoingWebinar} handleClose={() => setShow(false)} />
      )}
      <Main showGoBack backgroundImage={headerBackground}>
        <Header title="PODCASTS" className={classes.header}  titleClass='PODCASTS'>
          <br/>
          <Container>
            <p style={{ fontSize: 17 }}>Bienvenidos a Mieloma Múltiple Virtual Connect, un espacio de Takeda Oncología para escuchar y aprender sobre la actualidad en el manejo del Mieloma Múltiple en Argentina.</p>
            <br/>
            <h4 className={classes.titulo_podscats}>Relevancia de RWE en Mieloma múltiple</h4>
            <p className={classes.descripcion_podscats}>Cerrando esta serie de podcasts sobre la actualidad del Mieloma Múltiple en Argentina, el Dr. Patricio Duarte junto a la Dra. Natalia Schutz charlan sobre la relevancia del RWE en la práctica clínica   </p>

              <iframe title="1" src="https://open.spotify.com/embed/show/4DnphO367nH5UgQyx6LC3W?utm_source=generator"
                    width="100%" height="152" frameBorder="0" allowFullScreen={true}
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
            <br/>
            <br/>
            <br/>
            <hr/>
            <br/>
            <br/>
              <h4 className={classes.titulo_podscats}>Actualidad en el alto riesgo citogenético en Argentina</h4>
              <p className={classes.descripcion_podscats}>El Dr. Patricio Duarte junto a la Dra. Dorotea Fantl nos traen las novedades en el tratamiento de los pacientes con alto riesgo citogenético. Realidad de Argentina.</p>

              <iframe title="2" src="https://open.spotify.com/embed/episode/2BadwKzLaQkiXVW2TvglMI?utm_source=generator"
                    width="100%" height="152" frameBorder="0" allowFullScreen={true}
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
            <br/>
            <br/>
            <br/>
            <hr/>
            <br/>
            <br/>
              <h4 className={classes.titulo_podscats}>Tratamiento de pacientes intermedio-fit en MMRR</h4>
              <p className={classes.descripcion_podscats}>En esta oportunidad el Dr. Patricio Duarte es acompañado por la Dra. Natalia Schutz. Juntos nos traen sus consideraciones sobre los pacientes intermedio-fit. Cómo identificarlos, consideraciones y recomendaciones terapéuticas.</p>

              <iframe title="3" src="https://open.spotify.com/embed/episode/25N1Dj6tUABTqTXvqPi1oa?utm_source=generator"
                    width="100%" height="152" frameBorder="0" allowFullScreen={true}
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
            <br/>
            <br/>
            <br/>
            <hr/>
            <br/>
            <br/>
              <h4 className={classes.titulo_podscats}>Tratamiento de pacientes frágiles en MMRR</h4>
              <p className={classes.descripcion_podscats}>El Dr. Patricio Duarte junto al Dr. Cristian Seehaus debaten respecto a cómo identificar a los pacientes frágiles y sobre las recomendaciones terapéuticas.</p>


              <iframe title="4" src="https://open.spotify.com/embed/episode/2rGytTEWR9x2G8gyHQ3qoq?utm_source=generator"
                    width="100%" height="152" frameBorder="0" allowFullScreen={true}
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
            <br/>
            <br/>
            <br/>
            <hr/>
            <br/>
            <br/>

              <h4 className={classes.titulo_podscats}>Recomendaciones en el tratamiento de pacientes fit en MMRR</h4>
              <p className={classes.descripcion_podscats}>Nuevamente el Dr. Ariel Corzo acompaña al Dr. Patricio Duarte, esta vez conversando sobre el tratamiento para pacientes fit en MMRR.</p>


              <iframe title="5" src="https://open.spotify.com/embed/episode/6RXbE87L3HQHuDcsHvZUVr?utm_source=generator"
                    width="100%" height="152" frameBorder="0" allowFullScreen={true}
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
            <br/>
            <br/>
            <br/>
            <hr/>
            <br/>
            <br/>
              <h4 className={classes.titulo_podscats}>Refractariedad a Lenalidomida</h4>
              <p className={classes.descripcion_podscats}>El Dr. Patricio Duarte junto a la Dra. Claudia Shanley, nos traen sus consideraciones y recomendaciones acerca de este tipo de pacientes.</p>


            <iframe title="6" src="https://open.spotify.com/embed-podcast/episode/2TbsPGhc4coMH2hQF1NLtU?utm_source=generator" width="100%"
                    height="152" frameBorder="0" allowTransparency={true} allow="encrypted-media"></iframe>

            <br/>
            <br/>
            <br/>
            <hr/>
            <br/>
            <br/>
             <h4 className={classes.titulo_podscats}>Decisiones terapéuticas basadas en EMR y su alcance en Argentina</h4>
              <p className={classes.descripcion_podscats}>En este episodio acompaña al Dr. Patricio Duarte, la Dra. Claudia Shanley y la Bioq. Belén Venegas. Charlan acerca de las recomendaciones, fortalezas y debilidades de la citometría en la hematología y sobre el impacto que tiene la EMR en las decisiones terapéuticas de los pacientes con Mieloma Múltiple.</p>


            <iframe title="7" src="https://open.spotify.com/embed/episode/03w3eQyLiplrbqW24ySeXs?utm_source=generator"
                    width="100%" height="152" frameBorder="0" allowFullScreen={true}
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
            <br/>
            <br/>
            <br/>
            <hr/>
            <br/>
            <br/>

              <h4 className={classes.titulo_podscats}>¿Cuándo tratar las recaídas bioquímicas y clínicas?</h4>
              <p className={classes.descripcion_podscats}>El Dr. Patricio Duarte junto al Dr. Ariel Corzo conversan sobre los diferentes tipos de recaídas y cómo se toman las decisiones terapéutica en el día a día.</p>




            <iframe title="8" src="https://open.spotify.com/embed/episode/455C5bD3ffB66mg7NSSq8P?utm_source=generator"
                    width="100%" height="152" frameBorder="0" allowFullScreen={true}
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>



          </Container>
        </Header>

      </Main>
    </Layout>
  )
}
