import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm, SubmitHandler } from 'react-hook-form'
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import headerBackground from '@assets/images/speakers-banner.jpg'
import { Layout, Main, FormAlert, Header } from '@components'
import { useFormMedica } from '@hooks'
import { useGlobalStyles } from '@styles'
import { AuthContext, LoginContext } from '@contexts'

import { validationSchema } from './validations'
import { useStyles } from './styles'
import { RegisterFormType } from './types'
import { UserProfileModal } from '../../components/Layout/components/NavBar/components/UserNavItems/components'

const headTitle = 'Formulario Médica - Espacio Oncología / TAKEDA'
const headDescription = 'Forma parte de Espacio Oncología. información médica.'
const destinatario = process.env.REACT_APP_CORREO_DESTINATARIO //'soporte@magnetico.dev' //

export const FormMedica = () => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()

  const { isAuthenticated } = useContext(AuthContext)
  const { userLoggedIn } = useContext(AuthContext)
  
  const [isUserProfileModalOpen, setIsUserProfileModalOpen] = useState(false)
  const [copiarCorreo, setCopiarCorreo] = useState(false)

  const { setIsOpen } = useContext(LoginContext)

  const { register, errorMessage, isSuccess } = useFormMedica()

  const { push } = useHistory()

  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit: SubmitHandler<RegisterFormType> = async ({ ...data }) => {
    const datos: RegisterFormType = data
    datos.mailTo = destinatario!
    datos.mailFrom = userLoggedIn?.email || 'ejemplo@correo.com'
    datos.subject = 'SOLICITUD INFO - ESPACIO ONCOLOGIA'
    datos.sendMeACopy = data.sendMeACopy || false
    datos.request = data.request
    datos.userId = userLoggedIn?.id || ''
    datos.therapeuticAreasId = data?.therapeuticAreasId
    setCopiarCorreo(data.sendMeACopy)
    register(datos)
  }

  useEffect(() => {    
    if (!isAuthenticated) {
      setIsOpen(true)
    }
  }, [isAuthenticated, push, setIsOpen])

  return (
    <Layout headTitle={headTitle} headDescription={headDescription}>
      {isAuthenticated && (
        <Main showGoBack backgroundImage={headerBackground}>
          <Header title="SOLICITUD DE INFORMACIÓN MEDICA-CIENTÍFICA" className={classes.header} />
          <Container className={classes.container}>
            {!isSuccess ? (
              <form onSubmit={handleSubmit(onSubmit)} className={classes.contentWrapper}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <h3>
                      Por favor, completar el siguiente formulario:
                    </h3>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="name"
                      control={control}
                      as={
                        <TextField
                          label="Nombre"
                          placeholder={userLoggedIn?.name || 'ERROR, usuario no logeado'}
                          fullWidth
                          margin="normal"
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          inputProps={{ readOnly: true }}
                          defaultValue={userLoggedIn?.name || ''}
                          value={userLoggedIn?.name || ''}
                          className={classes.colorInput}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="lastname"
                      control={control}
                      as={
                        <TextField
                          label="Apellido"
                          placeholder={userLoggedIn?.lastName || 'ERROR, usuario no logeado'}
                          fullWidth
                          margin="normal"
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          inputProps={{ readOnly: true }}
                          defaultValue={userLoggedIn?.lastName || ''}
                          value={userLoggedIn?.lastName || ''}
                          className={classes.colorInput}
                        />
                      }
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <Controller
                      name="emailRemitente"
                      control={control}
                      as={
                        <TextField
                          label="Mail"
                          placeholder={userLoggedIn ? userLoggedIn.email : ''}
                          fullWidth
                          margin="normal"
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          autoComplete="off"
                          size="small"
                          inputProps={{ readOnly: true }}
                          defaultValue={userLoggedIn ? userLoggedIn.email : ''}
                          value={userLoggedIn ? userLoggedIn.email : ''}
                          className={classes.colorInput}
                        />
                      }
                    />
                    {errors.emailRemitente && <FormAlert>{errors.emailRemitente.message}</FormAlert>}
                  </Grid>
                  <Grid item sm={6} xs={12}>                    
                    <FormControl
                      variant="outlined"
                      size="small"
                      margin="normal"
                      style={{ width: '100%' }}
                    >
                      <InputLabel id="minutes-label">Patología</InputLabel>
                      <Controller
                        name="therapeuticAreasId"
                        control={control}
                        defaultValue=""
                        as={
                          <Select labelId="minutes-label" id="minutes" label="Patología">
                            <MenuItem value={1}>LINFOMA</MenuItem>
                            <MenuItem value={2}>MIELOMA MÚLTIPLE</MenuItem>
                            <MenuItem value={3}>CÁNCER DE PULMÓN</MenuItem>
                            <MenuItem value={4}>OTRAS ÁREAS DE INTERÉS</MenuItem>
                            {/*specialties.map(specialty => (
                              <MenuItem value={specialty}>{specialty}</MenuItem>
                            ))*/}
                          </Select>
                        }
                      />
                    </FormControl>                   
                    {errors.therapeuticAreasId && <Alert severity="error">Este campo es obligatorio</Alert>}
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      name="asunto"
                      control={control}
                      as={
                        <TextField
                          label="Asunto"
                          placeholder="Solicitud de información Médica desde Espacio Oncología"
                          fullWidth
                          margin="normal"
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          autoComplete="off"
                          size="small"
                          inputProps={{ readOnly: true }}
                          defaultValue="Solicitud de información Médica desde Espacio Oncología"
                          value="Solicitud de información Médica desde Espacio Oncología"
                          className={classes.colorInput}
                        />
                      }
                    />
                    {errors.asunto && <FormAlert>{errors.asunto.message}</FormAlert>}
                  </Grid>

                  <Grid item xs={12}>
                    <div className={classes.labelForm}>
                      <span>Detallar su solicitud</span>
                    </div>
                    <Controller
                      name="request"
                      control={control}
                      as={
                        <textarea
                          aria-label="empty textarea"
                          style={{ width: '100%' }}
                          rows={4}
                          className={classes.textAreaForm}
                        />
                      }
                    />
                    {errors.request && <FormAlert>{errors.request.message}</FormAlert>}
                  </Grid>
                  <div>
                    <Controller
                      name="sendMeACopy"
                      control={control}
                      defaultValue={true}
                      render={props => (
                        <FormControlLabel
                          label={<>Enviar una copia al mail del usuario en sesión</>}
                          control={
                            <Checkbox
                              checked={props.value}
                              onChange={e => props.onChange(e.target.checked)}
                              color="primary"
                            />
                          }
                        />
                      )}
                    />
                  </div>
                </Grid>

                {errorMessage && (
                  <div
                    className={`${globalClasses.textCenter} ${globalClasses.mediumBottomSeparator}`}
                  >
                    <FormAlert>{errorMessage}</FormAlert>
                  </div>
                )}

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Enviar
                </Button>
              </form>
            ) : (
              <div className={classes.contentWrapper}>
                  <div>
                    <Typography component="h3" variant="h3" align="center">
                      ¡Consulta enviada!
                    </Typography>
                    <br />
                    <Typography component="p" align="center">
                      Hemos enviado tu consulta. Te estaremos respondiendo a la brevedad. 
                    </Typography>
                  </div>
              </div>
            )}
          </Container>
        </Main>
      )}

      <UserProfileModal
        isOpen={isUserProfileModalOpen}
        userSelected={userLoggedIn!}
        handleClose={() => setIsUserProfileModalOpen(false)}
      />
    </Layout>
  )
}