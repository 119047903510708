import { useState } from 'react'
import { ERRORS } from '@constants'
import { ErrorHandlers } from '@types'

const useErrorHandler = () => {
  const [errorMessage, setError] = useState('')

  const setErrorMessage = (type: string, field?: string, customMessage?: string) => {
    const UppercaseField = field?.toUpperCase() ?? 'DEFAULT'

    setError(() => {
      switch (type) {
        case ErrorHandlers.DuplicateEntryError:
          return ERRORS.DUPLICATE_ENTRY_ERROR[UppercaseField]

        case ErrorHandlers.Forbidden:
          return ERRORS.FORBIDDEN[UppercaseField]

        case ErrorHandlers.Unauthorized:
          return ERRORS.UNAUTHORIZED[UppercaseField]

        case ErrorHandlers.ResourceFieldInUse:
          return ERRORS.RESOURCE_FIELD_IN_USE[UppercaseField]

        case ErrorHandlers.ResourceNotFound:
          return ERRORS.RESOURCE_NOT_FOUND[UppercaseField]

        case ErrorHandlers.CustomMessage:
        case ErrorHandlers.ValidationError:
          return customMessage!

        case ErrorHandlers.GenericError:
          return ERRORS.GENERIC_ERROR[UppercaseField]

        case ErrorHandlers.UserAlreadyRegistered:
          return customMessage ?? 'Error interno'

        default:
          return ''
      }
    })
  }

  return { errorMessage, setErrorMessage }
}

export { useErrorHandler }
