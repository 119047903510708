import React, { FC, useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'

import { QUERY_KEYS, TherapeuticAreasGlobalApi } from '@api'

import { useStyle } from './styles'

// TODO handle params - isActive NavLink prop

export const CategoriesFilter: FC = () => {
  const classes = useStyle()
  const { search } = useLocation()

  const params = useMemo(() => new URLSearchParams(search), [search])

  const { data } = useQuery(
    [QUERY_KEYS.THERAPEUTIC_AREAS],
    TherapeuticAreasGlobalApi.getTherapeuticAreas,
  )

  return (
    <>
      <NavLink
        to={() => {
          params.delete('therapeutic-area')
          return {
            pathname: '/webinars',
            search: `?${params.toString()}`,
          }
        }}
        className={classes.navLink}
        isActive={() => {
          const p = new URLSearchParams(search)
          return !p.has('therapeutic-area')
        }}
        exact
      >
        TODAS LAS ÁREAS
      </NavLink>
      {data?.map(therapeuticArea => (
        <NavLink
          to={() => {
            params.set('therapeutic-area', therapeuticArea.id.toString())
            return {
              pathname: '/webinars',
              search: `?${params.toString()}`,
            }
          }}
          className={classes.navLink}
          key={therapeuticArea.name}
          isActive={() => {
            const p = new URLSearchParams(search)
            return p.get('therapeutic-area') === therapeuticArea.id.toString()
          }}
        >
          {therapeuticArea.name}
        </NavLink>
      ))}
    </>
  )
}
