import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from '@styles'
import { LAYOUT } from '@constants'

const { NAV } = LAYOUT

export const useStyles = makeStyles(theme => ({
  navBar: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    backgroundColor: '#FFF',
    height: `${NAV.HEIGHT}vh`,
    minHeight: 60,
  },
  navBarWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    margin: '0 auto',
  },
  logo: {
    '& > img': {
      height: '5.5vh',
      minHeight: 30,
      [theme.breakpoints.down('sm')]: {
        height: '4.1vh',
        minHeight: 24,
      },
    },
  },
  toggleMenu: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: 20,
    },
  },
  drawer: {
    width: 300,
  },
  navbarMenu: {
    display: 'inline-block',
    marginLeft: 'auto',
  },
  navList: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight: 3,
      width: 240,
    },
  },
  navItem: {
    position: 'relative',
    padding: 0,
  },
  navLink: {
    display: 'inline-block',
    fontSize: 12,

    fontWeight: 700,
    color: COLORS.GRAY,
    textTransform: 'uppercase',
    textDecoration: 'none',
    padding: '8px 20px',
    [theme.breakpoints.up('lg')]: {
      fontSize: 15,
    },
  },
  userNavItem: {
    display: 'flex',
    position: 'relative',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      '&::before': {
        content: "'|'",
        display: 'inline-block',
        marginRight: 20,
        color: theme.palette.text.primary,
      },
    },
  },
  menuNavItem:{
    display: 'flex',
    position: 'relative',
    whiteSpace: 'nowrap',

  },
  login: {
    display: 'flex',
    color: COLORS.PRIMARY,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      '&::before': {
        content: "'|'",
        display: 'inline-block',
        marginRight: 20,
        color: theme.palette.text.primary,
      },
    },
  },
}))
