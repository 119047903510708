import React, { FC } from 'react'
import { Switch } from 'react-router-dom'
import { Route, RouteComponentProps } from 'react-router'

import { Podcasts } from './Podcasts'

export const PodcastsRouter: FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route path={match.path} component={Podcasts} />
    </Switch>
  )
}
