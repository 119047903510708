import moment from 'moment'

moment.locale('es', {
  months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
    '_',
  ),
  monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
  monthsParseExact: true,
  weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sábado'.split('_'),
  weekdaysShort: 'Dom_Lun_Mar_Mie_Jue_Vie_Sáb'.split('_'),
  weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
  weekdaysParseExact: true,
})

const FRIENDLY_DATE = 'D [de] MMMM YYYY '
const FRIENDLY_DATE_WITH_HS = 'D [de] MMMM YYYY - H:mm [hs]'
const SHORT_DATE = 'D MMM'
const HOUR_AND_MINUTES = 'H:mm [hs]'
const SHORT_DATE_AND_HOUR = 'D MMM [-] H:mm [hs]'
const SHORT_DATE_AND_YEAR = 'D MMM [-] YYYY'
const SHORT_MONTH_AND_YEAR = 'MMMM [-] YYYY'

export {
  FRIENDLY_DATE_WITH_HS,
  FRIENDLY_DATE,
  SHORT_DATE,
  HOUR_AND_MINUTES,
  SHORT_DATE_AND_HOUR,
  SHORT_DATE_AND_YEAR,
  SHORT_MONTH_AND_YEAR,
}
