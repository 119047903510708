import { Webinar } from '@types'
import { api } from '@utils'

export interface GetWebinarsResponse {
  webinar: Webinar
}

const getOngoingWebinar = async () => {
  const ongoingWebinar = await api.get<GetWebinarsResponse>('/webinars/ongoing')
  return ongoingWebinar.data.webinar
}

export const GetHomeInfo = { getOngoingWebinar }
