import React, { FC } from 'react'
import { Switch } from 'react-router-dom'
import { Route, RouteComponentProps } from 'react-router'

import { Speakers } from './Speakers'
import { SpeakerDetails } from './SepakerDetails'

export const SpeakersRouter: FC<RouteComponentProps> = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}/therapeutic-area/:id`} component={Speakers} />
      <Route exact path={`${match.path}/:id`} component={SpeakerDetails} />
      <Route exact path={match.path} component={Speakers} />
    </Switch>
  )
}
