import React, { FC } from 'react'
import { Button } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useHistory } from 'react-router'

import { useStyles } from './styles'

interface Props {
  className?: string
  backgroundImage?: string
  showGoBack?: boolean
  showGoHome?: boolean
}

const Main: FC<Props> = ({ children, className, backgroundImage, showGoBack, showGoHome }) => {
  const classes = useStyles()
  const { goBack, push } = useHistory()

  return (
    <main
      className={`${classes.main} ${className}`}
      style={{ backgroundImage: backgroundImage ? `url(${backgroundImage})` : '' }}
    >
      {children}
      {showGoBack && (
        <Button onClick={goBack} className={classes.btnBack}>
          <ArrowBackIosIcon fontSize="small" color="primary" />
          Volver
        </Button>
      )}
      {showGoHome && (
        <Button onClick={() => push('/')} className={classes.btnBack}>
          <ArrowBackIosIcon fontSize="small" color="primary" />
          Ir a la pagina principal
        </Button>
      )}
    </main>
  )
}

export { Main }
