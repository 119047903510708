const COLORS = {
  GRAY: '#9a9a9a',
  GRAY_DARK: '#707070',
  GRAY_LIGHT: '#EFEFEF',
  WHITE: '#fff',
  GRAY_HEADER: '#535353',
  PRIMARY: '#FC1622',
  PINK_SWAN: '#b7b7b7',
  ORANGE: '#e87c00',
  RED: '#ff0202',

  // TODO reemplazar
  GRAY_FOOTER: '#9a9a9a',
  GRAY_LOGIN: '#e4e4e4',
}

export { COLORS }
